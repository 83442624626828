import React, {useEffect} from 'react'
import FooterSubscribe from '../components/FooterSubscribe';
import HomeFaq from '../components/HomeFaq'
import HomeNews from '../components/HomeNews'
import HomeContact from '../components/HomeContact';
import Project from '../components/Project';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
  <Project/>
    <HomeContact/>
    <HomeFaq/>
   {/* <HomeNews/> */}
  {/* <FooterSubscribe/> */}
   
  </div>
  )
}

export default Blogs