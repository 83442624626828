import React, { useEffect, useState } from 'react';
import News1 from '../assets/img/aboutus1.png'
import News11 from '../assets/img/logos.png'
import au1 from '../assets/img/au1.png';
import au2 from '../assets/img/au2.png';
import img1 from '../assets/img/1.png';
import img2 from '../assets/img/2.png';
import img3 from '../assets/img/3.png';
import img4 from '../assets/img/4.png';
import img5 from '../assets/img/5.png';
import img6 from '../assets/img/6.png';
import img7 from '../assets/img/7.png';
import img8 from '../assets/img/8.png';
import logo1 from '../assets/img/logo/1.png';
import logo2 from '../assets/img/logo/2.png';
import logo3 from '../assets/img/logo/3.png';
import logo4 from '../assets/img/logo/4.png';
import logo5 from '../assets/img/logo/5.png';
import logo6 from '../assets/img/logo/6.png';

import { Link } from 'react-router-dom';
const Aboutus = () => {
  useEffect(() => {
    const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
    const scrollHandler = () => {
        testimonialElements.forEach(testimonial => {
            const bounding = testimonial.getBoundingClientRect();
            if (bounding.top < window.innerHeight) {
                testimonial.classList.add('show');
            }
        });
    };
    window.addEventListener('scroll', scrollHandler);
    // Cleanup
    return () => {
        window.removeEventListener('scroll', scrollHandler);
    };
}, []);

  return (
    <>

    <img src={News1} className='img-fluid w-100 news-img' />
<div className='container '>
<div className="my-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div className='text-center aboutushover' style={{ border: '1px solid #4A4A4A', padding: '10px', borderRadius:"10px", color:"#F07E01" }}>
    <h4>WELCOME TO BHIMA PROPERTIES</h4>
  </div>
</div>
<h2 className='text-center aboutushover'>We Are More Than Real Estate. At Bhima Properties, 
We Aim To Build Your Dreams.</h2>

<p>Since 2014, Bhima Properties has been one of the prime real estate builders and developers in Mysore. Hailing from the princely state we take pride in providing you with premium quality residential living plots where you can build your dream home.</p>
<p>Our specialization is the careful attention to creating spacious landscapes for truly open-air living that give you a sense of space you have never experienced before.</p>
<p>All Bhima Properties projects boast of gated community living space, huge open-air landscapes and gardens, 100% Vastu Compliant plots, 100% MUDA Approved Projects, Clear Titles and Easy Financing Options with partner Banks.</p>

<div className='aboutushover bhimaaboutus123' style={{border:"1px solid #D9D9D9", background:"rgb(248, 248, 248)", padding:"3rem", borderRadius:"10px"}}>
  <h6 style={{background:"#F07E01", border:"1px solid #F07E01", padding:"5px", color:"white", display:"inline"}}>Most Trusted Names In Karnataka</h6><br/><br/>
<h6>Bhima Properties is part of the group of companies that have brought to you Shree Mahalakshmi Sweets, a legendary name in Mysuru, and Shivas Group of Hotels, a multi-award winning and most trusted Hotel group in Bangalore. The group of companies are managed and promoted by Mr. Shiva Kumar, who has established a formidable track record of over 30 years of bringing top quality products and services that today are the pride of Karnataka.</h6>

</div>
{/* <img src={News11} className='img-fluid w-100 news-img aboutushover' /> */}

<div className='container desktop-banner'>
  <Link to="/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto'>
<img src={logo6} className='img-fluid w-100 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>SHIVAS
Group</h2>
<h6>Over the last three decades, the Shiva’s Group has firmly established itself as one of the leading and most
successful in Sweets and Saviours, Real Estate and Hospitality Industry in South India by imprinting its indelible mark across
all asset classes. Founded in 1991, a leap that has been inspired by Mr. Shivakumar and marshaled by his most
efficient team backing him up in all his ventures.</h6>
<h6>The company has diversified into 3 entities each of them spearheaded by individuals with adroit capacity.
The entities are as varied as the Traditional Indian Sweets done by "Shree Mahalakshmi Sweets" to the redefinition of
hospitality Services by The "Shivas Hotels" to the real estate space across asset classes by "Bhima Properties",
which has a wide diverse portfolio covering from residential, commercial, retail, leisure and hospitality segments.</h6>

</div>
</div>
</Link>
<hr/>
  <Link to="https://www.shreemahalakshmisweets.com/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>

<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shree Mahalakshmi Sweets
Taste of Mysuru!</h2>
<h6>Shree Mahalakshmi Sweets is a name synonymous with sweetness since 1991.
With the rich experience of our nearly 3 decades, we offer the cleanest, the tastiest and the best
quality products produced using the most modern technology to suit the contemporary taste.</h6>
<h6>• These latest developments however do not stop us from continuing the traditional tastes and
recipes
The taste of each and every item is looked after personally by the specialists to carry the rich
tradition in to the 4th decade.</h6>
<h6>• ‘Taste of Mysuru’ is the motto of Mr. Shivakumar founder of Shree Mahalakshmi Sweets since
its inception from 1991, he has left no stone unturned, his penchant for quality has put this Shree
Mahalakshmi Sweets on the top.</h6>
</div>
<div className='col-md-2 m-auto'>
<img src={logo2} className='img-fluid w-100 news-img' />
</div>
</div>
</Link><hr/>
  <Link to="https://www.shivashotels.com/en/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto'>
<img src={logo4} className='img-fluid w-100 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shivas Group of Hotels</h2>
<div className='d-flex gap-3 my-3'>
<Link to="https://www.shivashotels.com/en/"  style={{textDecoration:"none"}}>
            <button className='btn load-more-projects px-4' style={{borderRadius:'10px !important'}}> Shivas Gateway </button></Link>
            <Link to="https://www.shivasgalaxy.com/en/"  style={{textDecoration:"none"}}>
            <button className='btn load-more-projects px-4' style={{borderRadius:'10px !important'}}>Shivas Galaxy</button></Link>
</div>
{/* <h3>(   <Link to="https://www.shivashotels.com/en/" style={{textDecoration:"none", color:"black"}}>Shivas Gateway</Link> &  <Link to="https://www.shivasgalaxy.com/en/" style={{textDecoration:"none", color:"black"}}>Shivas Galaxy</Link> )</h3> */}
<h6>Since its inception, The Shivas Group of Hotels has established a striking presence in the hospitality industry. Offering an eclectic blend of traditional hospitality and modern conveniences, we take pride in delivering a unique local experience as well as an assurance of quality and comfort. Each of our hotels depicts perfection with respect to the seamless services and contemporary facilities that we offer to guarantee a hassle-free stay for our esteemed guests.</h6>
</div>
</div>
</Link><hr/>
  {/* <Link to="https://www.shivashotels.com/en/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>

<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shivas Gateway</h2>
<h6>• Our hotel is distinguished by design innovations and the latest lifestyle trends
combined.Our 44 rooms offer free high-speed, wireless internet, and a runaway
view provides the perfect retreat after a busy day. Taste the most delicious food at
our Ambari restaurant . Shivas Gateway Hotel is located in Devanahalli-Bangalore,
6.2 km from Devanahalli Fort. Among the facilities of this property are a restaurant,
a 24-hour front desk and room service, along with free WiFi throughout the
property. The hotel has family rooms. Accommodation at Shivas Gateway Hotel
includes elegant Bespoke spaces to suit the guest mood and state of mind.</h6>
<h6>• Treat yourself to our lavish minibar as you cherish the exquisite design and elegant
décor of your suite. Each of our suites comes with world-class high-tech amenties,
elegant chinaware, electrical hot pots, cosy furniture, and exuberant furnishing.</h6>
</div>
<div className='col-md-2 m-auto'>
<img src={logo5} className='img-fluid w-100 news-img' />
</div>
</div>
</Link><hr/>
  <Link to="https://www.shivasgalaxy.com/en/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto'>
<img src={logo3} className='img-fluid w-100 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shivas Galaxy</h2>
<h6>• Our hotel is distinguished by design innovations and the latest lifestyle trends
combined. Our 87 rooms offer free high-speed, wireless internet, balcony view
provides the perfect retreat after a busy day. Taste the most delicious food at our
multi-cuisine restaurant the serene an evening chats at Cafe Nisa. We offer a
Ballroom too that can host a grand event upto 125 guests. Boasting a fitness center
and views of the city, Shivas Galaxy Hotel is located in Devanahalli-Bangalore, 6.2
km from Devanahalli Fort. Among the facilities of this property are a restaurant, a
24-hour front desk and room service, along with free WiFi throughout the property.</h6>
<h6>• Accommodation at Shivas Galaxy Hotel includes elegant Bespoke spaces to suit the
guest mood and state of mind.</h6>
</div>
</div>
</Link><hr/> */}
  <Link to="/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>

<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Bhima Properties</h2>
<h6>• Bhima Properties stands as a distinguished entity responsible for crafting prestigious and cutting-edge facilities in
Mysuru. Since its establishment in 2014, Bhima Properties has been driven by the aspiration to become a brand
synonymous with admiration. Headquartered in Mysuru, the company specializes in the construction of villas and the
development of residential projects within the region. Built upon bedrock of trust and fortified by the pillars of quality,
Bhima Properties is seamlessly entwined with the renowned name of Shree Mahalakshmi Sweets. This venture was
founded and nurtured by Mr. Shivakumar, a stalwart in the sweet-making industry with an illustrious 32-year career,
leaving no effort spared in ensuring excellence.</h6>
<h6>• Vision: "To emerge as a pioneering force in the real estate industry, setting new standards of excellence while adhering
to stringent legal norms. Bhima Properties aspires to be a trusted name synonymous with innovation, integrity, and
exceptional quality, ensuring every project meets RERA registration, MUDA approval, and maintains clear titles."</h6>
<h6>• Mission: "At Bhima Properties, we're on a mission to create exceptional living spaces, led by a dynamic team of young
professionals under the guidance of our chairman. Committed to exceeding expectations, we contribute to community
growth through innovation and a client-centric approach, making dreams a reality in the real estate landscape."</h6>
</div>
<div className='col-md-2 m-auto'>
<img src={logo1} className='img-fluid w-100 news-img' />
</div>
</div>
</Link>
</div>


<div className='container mobile-banner'>
  <Link to="/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto text-center'>
<img src={logo6} className='img-fluid w-50 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>SHIVAS
Group</h2>
<h6>Over the last three decades, the Shiva’s Group has firmly established itself as one of the leading and most
successful in Sweets and Saviours, Real Estate and Hospitality Industry in South India by imprinting its indelible mark across
all asset classes. Founded in 1991, a leap that has been inspired by Mr. Shivakumar and marshaled by his most
efficient team backing him up in all his ventures.</h6>
<h6>The company has diversified into 3 entities each of them spearheaded by individuals with adroit capacity.
The entities are as varied as the Traditional Indian Sweets done by "Shree Mahalakshmi Sweets" to the redefinition of
hospitality Services by The "Shivas Hotels" to the real estate space across asset classes by "Bhima Properties",
which has a wide diverse portfolio covering from residential, commercial, retail, leisure and hospitality segments.</h6>

</div>
</div>
</Link><hr/>
  <Link to="https://www.shreemahalakshmisweets.com/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto text-center'>
<img src={logo2} className='img-fluid w-50 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shree Mahalakshmi Sweets
Taste of Mysuru!</h2>
<h6>Shree Mahalakshmi Sweets is a name synonymous with sweetness since 1991.
With the rich experience of our nearly 3 decades, we offer the cleanest, the tastiest and the best
quality products produced using the most modern technology to suit the contemporary taste.</h6>
<h6>• These latest developments however do not stop us from continuing the traditional tastes and
recipes
The taste of each and every item is looked after personally by the specialists to carry the rich
tradition in to the 4th decade.</h6>
<h6>• ‘Taste of Mysuru’ is the motto of Mr. Shivakumar founder of Shree Mahalakshmi Sweets since
its inception from 1991, he has left no stone unturned, his penchant for quality has put this Shree
Mahalakshmi Sweets on the top.</h6>
</div>

</div>
</Link><hr/>
  <Link to="https://www.shivashotels.com/en/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto text-center'>
<img src={logo4} className='img-fluid w-50 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shivas Group of Hotels</h2>
{/* <h3>(   <Link to="https://www.shivashotels.com/en/" style={{textDecoration:"none", color:"black"}}>Shivas Gateway</Link> &  <Link to="https://www.shivasgalaxy.com/en/" style={{textDecoration:"none", color:"black"}}>Shivas Galaxy</Link> )</h3> */}
<div className='d-flex gap-3 my-3' style={{justifyContent:"center"}}>
<Link to="https://www.shivashotels.com/en/"  style={{textDecoration:"none"}}>
            <button className='btn load-more-projects px-4' style={{borderRadius:'10px !important', fontSize:'12px'}}> Shivas Gateway </button></Link>
            <Link to="https://www.shivasgalaxy.com/en/"  style={{textDecoration:"none"}}>
            <button className='btn load-more-projects px-4' style={{borderRadius:'10px !important', fontSize:'12px'}}>Shivas Galaxy</button></Link>
</div>
<h6>Since its inception, The Shivas Group of Hotels has established a striking presence in the hospitality industry. Offering an eclectic blend of traditional hospitality and modern conveniences, we take pride in delivering a unique local experience as well as an assurance of quality and comfort. Each of our hotels depicts perfection with respect to the seamless services and contemporary facilities that we offer to guarantee a hassle-free stay for our esteemed guests.</h6>
</div>
</div>
</Link><hr/>
  {/* <Link to="https://www.shivashotels.com/en/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto text-center'>
<img src={logo5} className='img-fluid w-50 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shivas Gateway</h2>
<h6>• Our hotel is distinguished by design innovations and the latest lifestyle trends
combined.Our 44 rooms offer free high-speed, wireless internet, and a runaway
view provides the perfect retreat after a busy day. Taste the most delicious food at
our Ambari restaurant . Shivas Gateway Hotel is located in Devanahalli-Bangalore,
6.2 km from Devanahalli Fort. Among the facilities of this property are a restaurant,
a 24-hour front desk and room service, along with free WiFi throughout the
property. The hotel has family rooms. Accommodation at Shivas Gateway Hotel
includes elegant Bespoke spaces to suit the guest mood and state of mind.</h6>
<h6>• Treat yourself to our lavish minibar as you cherish the exquisite design and elegant
décor of your suite. Each of our suites comes with world-class high-tech amenties,
elegant chinaware, electrical hot pots, cosy furniture, and exuberant furnishing.</h6>
</div>

</div>
</Link><hr/>
  <Link to="https://www.shivasgalaxy.com/en/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto text-center'>
<img src={logo3} className='img-fluid w-50 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Shivas Galaxy</h2>
<h6>• Our hotel is distinguished by design innovations and the latest lifestyle trends
combined. Our 87 rooms offer free high-speed, wireless internet, balcony view
provides the perfect retreat after a busy day. Taste the most delicious food at our
multi-cuisine restaurant the serene an evening chats at Cafe Nisa. We offer a
Ballroom too that can host a grand event upto 125 guests. Boasting a fitness center
and views of the city, Shivas Galaxy Hotel is located in Devanahalli-Bangalore, 6.2
km from Devanahalli Fort. Among the facilities of this property are a restaurant, a
24-hour front desk and room service, along with free WiFi throughout the property.</h6>
<h6>• Accommodation at Shivas Galaxy Hotel includes elegant Bespoke spaces to suit the
guest mood and state of mind.</h6>
</div>
</div>
</Link><hr/> */}
  <Link to="/" style={{textDecoration:"none", color:"black"}}>
<div className='row my-5 aboutushover bhimaaboutus123'>
<div className='col-md-2 m-auto text-center'>
<img src={logo1} className='img-fluid w-50 news-img' />
</div>
<div className='col-md-10 m-auto '>
<h2 style={{color:"rgb(240, 126, 1)"}}>Bhima Properties</h2>
<h6>• Bhima Properties stands as a distinguished entity responsible for crafting prestigious and cutting-edge facilities in
Mysuru. Since its establishment in 2014, Bhima Properties has been driven by the aspiration to become a brand
synonymous with admiration. Headquartered in Mysuru, the company specializes in the construction of villas and the
development of residential projects within the region. Built upon bedrock of trust and fortified by the pillars of quality,
Bhima Properties is seamlessly entwined with the renowned name of Shree Mahalakshmi Sweets. This venture was
founded and nurtured by Mr. Shivakumar, a stalwart in the sweet-making industry with an illustrious 32-year career,
leaving no effort spared in ensuring excellence.</h6>
<h6>• Vision: "To emerge as a pioneering force in the real estate industry, setting new standards of excellence while adhering
to stringent legal norms. Bhima Properties aspires to be a trusted name synonymous with innovation, integrity, and
exceptional quality, ensuring every project meets RERA registration, MUDA approval, and maintains clear titles."</h6>
<h6>• Mission: "At Bhima Properties, we're on a mission to create exceptional living spaces, led by a dynamic team of young
professionals under the guidance of our chairman. Committed to exceeding expectations, we contribute to community
growth through innovation and a client-centric approach, making dreams a reality in the real estate landscape."</h6>
</div>

</div>
</Link>
</div>


<h2 className='my-3 bhimaaboutus123' style={{color:"#F07E01"}}>All Bhima Properties Projects Specialize In:</h2>
<ul className='bhimaaboutus123'>
<li>Offering gated communities with state-of-the-art amenities.</li>
<li>Providing luxury residential villas and land development villas.</li>
<li>Eco-Friendly Landscapes and open spaces</li>
<li>Excellent location, quality planning and clear titles.</li>
<li>Led by a group of dynamic, ambitious and committed team.</li>
</ul>

<p className='bhimaaboutus123'>Bhima Properties has been a part of Shree Mahalakshmi Sweets, Mysuru for many years. It was established by Mr. Shiva Kumar and has built a track record for quality and excellence. The company’s location, planning, and titles are all excellent, making it an ideal choice for Indian and NRI investors.
Bhima Properties is committed to offering gated communities with state-of-the-art amenities in an eco-friendly environment that would satisfy & exceed all of its client’s needs. The group is led by a group of dynamic, ambitious and committed team of young professionals from civil engineering, architecture, designers and lawyers giving home buyers and investors all around comfort of working with industry professionals at every stage of your purchase.</p>
<hr/>

<h2 className='my-3 text-center bhimaaboutus123'>Why invest in this project</h2>
<div className='row bhimaaboutus123' style={{margin:"1rem"}}>
  <div className='col-md-2 col-0'></div>
<div className='col-md-4 col-11 my-3' style={{border:"1px solid #F8F8F8", background:"#F8F8F8", padding:"0", borderRadius:"0px 0px 10px 10px" , margin:"0.5rem"}}>
<img src={au1} className='img-fluid w-100 news-img aboutushover' style={{borderRadius:"10px 10px 0px 0px"}}/>
<ul style={{padding:"2rem"}}>
  <li>To be the preferred choice of investors in India and abroad who are looking for a perfect blend 
of luxury plots at premium locations with the advantage of cost appreciation.</li>
  <li>Bhima Properties is a leading real estate developer in commercial, retail, and residential market and our goal is to provide profitable developments that meet the needs of our customers.</li>
  <li>At Bhima Properties, we drive the entrepreneurial and aggressive spirit that prioritizes teamwork, innovation, professionalism, 
and building long-term relationships. We hold ourselves to 
the highest standard of integrity.</li>

</ul>
</div>
<div className='col-md-4 col-11 my-3' style={{border:"1px solid #F8F8F8", background:"#F8F8F8", padding:"0", borderRadius:"0px 0px 10px 10px" , margin:"0.5rem"}}>
<img src={au2} className='img-fluid w-100 news-img aboutushover' style={{borderRadius:"10px 10px 0px 0px"}}/>

<ul style={{padding:"2rem"}}>
  <li>Committed to Customer Satisfaction: We prioritize our customers and strive to exceed their expectations in all aspects of our business.</li>
  <li>Champions of Leadership: We embrace an entrepreneurial spirit and take a proactive approach to leadership. We foster a culture of respect and empowerment for our employees and encourage prudent risk-taking.</li>
  <li>Committed to Employee Growth: We are committed to helping our employees reach their full potential across interpersonal, professional and technical and groom them to be future leaders.</li>

</ul>
</div>

<div className='col-md-2 col-0'></div>

</div>

<div className='my-3 aboutushover bhimaaboutus123' style={{border:"1px solid #F07E01", borderRadius:"10px", padding:"2rem", textAlign:"center"}}>
  <h2 style={{color:"#F07E01"}}>PROJECT GALLERY</h2>
  <p>Our home is at the heart of the design, allowing us to engage with our community through talks and events, and uphold our company culture with film screenings, yoga classes and team lunches.</p>
</div>
<div className='row my-3 bhimaaboutus123'>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img1} className='img-fluid w-100 news-img' />

</div>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img2} className='img-fluid w-100 news-img' />

</div>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img3} className='img-fluid w-100 news-img' />

</div>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img4} className='img-fluid w-100 news-img' />

</div>

</div>
<div className='row my-3 bhimaaboutus123'>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img5} className='img-fluid w-100 news-img' />

</div>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img6} className='img-fluid w-100 news-img' />

</div>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img7} className='img-fluid w-100 news-img' />

</div>
<div className='col-md-3 col-6 my-1 aboutushover'>
<img src={img8} className='img-fluid w-100 news-img' />

</div>

</div>
</div>

</>
  )
}

export default Aboutus