import React, { useEffect, useState } from 'react';
import bannerImage3 from '../assets/img/banner/m4.png'
import bannerImage1 from '../assets/img/banner/d4.png'
import ame1 from '../assets/img/am/am1.png'
import ame2 from '../assets/img/am/am2.png'
import ame3 from '../assets/img/am/am3.png'
import ame4 from '../assets/img/am/am4.png'
import ame5 from '../assets/img/am/am5.png'
import ame6 from '../assets/img/am/am6.png'
import ame7 from '../assets/img/am/am7.png'
import ame8 from '../assets/img/am/am8.png'
import ame9 from '../assets/img/am/am9.png'
import ame11 from '../assets/img/am/am11.png'
import { Link } from 'react-router-dom'
import Gallery from '../components/gallery4'
import Contact from '../components/HomeContact'
import { Helmet } from 'react-helmet';

export default function HomeNews() {
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
    return (
        <>

<Helmet>
                <meta charSet="utf-8" />
                <title>Krithika Lake View | Serene Living in Mysore | Bhima Properties</title>
                <meta name="description" content="Experience serene living with stunning lake views at Krithika Lake View, Mysore. Secure your ideal property with Bhima Properties today." />
                <link rel="canonical" href="https://bhimaproperties.com/krithika-lake-view-project" />
            </Helmet>






        <div>
            <Link to="/contact-us">
             <img src={bannerImage1} alt="Banner 1" className='desktop-banner' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

<img src={bannerImage3} alt="Banner 1" className='mobile-banner' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></Link>
        </div>

        <div className='container my-3'>
            <h1 className='text-center my-5 aboutushover'>KRITHIKA LAKE VIEW  </h1>
            <h5 className='bhimaaboutus123'>Embrace your paradise with breathtaking lake views at Krithika Lake View!</h5>
            <h5 className='bhimaaboutus123'>Nestled just 200 meters from NH766 and minutes from Mysore Airport, this ongoing project offers a limited opportunity to own your dream plot! Choose from diverse sizes (9*12, 9*15 And Odd (mts) Sites) in this Muda-approved and RERA-registered community, boasting stunning lake vistas. With 90% already sold! Enjoy first-class amenities like 24/7 security, lush gardens, and wide roads, all within easy reach of city conveniences.</h5>
            <br/>
            <h5 className='bhimaaboutus123'><strong>Site Dimension :</strong> 9*12, 9*15 And Odd (mts) Sites</h5>
            <h5 className='bhimaaboutus123'><strong>Total Area :</strong> 6 Acres 14 Guntas</h5>
            <h5 className='bhimaaboutus123'><strong>Total Sites :</strong> 108 Sites</h5>
            <h5 className='bhimaaboutus123'><strong>90% Sold & 10% Available</strong></h5>
            <br/>

            <h5 className='bhimaaboutus123'><strong>Location :</strong>Gejjagalli Village, Mysore</h5>

            <h5 className='bhimaaboutus123'><strong>Muda Approved & RERA Registered</strong></h5>
            <br/>
<h1 className='text-center my-5 bhimaaboutus123'>Amenities</h1>
<div className='container bhimaaboutus123'>
<div className='row' style={{border:"1px solid rgb(242, 223, 189)", background:"rgb(242, 223, 189)", padding:"3rem",borderRadius:"1rem"}}>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame1} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>24/7 Security</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame2} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>24/7 Water Supply</h6>
        </div>
 
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame4} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Avenue Lined Trees</h6>
        </div>
    
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame6} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>LandScaped Gardens</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame7} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Wide Asphalted Roads</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame8} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Jogging Track in the Parks</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame9} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>U shaped Drainage System</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame3} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Electricity supply with Street Lights</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame5} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Underground Sewage system for each Plot</h6>
        </div>
</div>

<h1 className='text-center my-5 bhimaaboutus123'>Nearby Locations </h1>
<div className='container bhimaaboutus123'>

<ul style={{fontSize:"1.5rem"}}>
<li className='text-left'>200 meters from <span style={{fontWeight:"600"}}>NH 766</span></li>
<li className='text-left'>2.5 kms before <span style={{fontWeight:"600"}}>Mysore Airport</span></li>
<li className='text-left'>1.5 Kms from <span style={{fontWeight:"600"}}>JP Nagar Ring Road</span></li>
<li className='text-left'>1.5 Kms from <span style={{fontWeight:"600"}}>APMC Yard</span></li>
<li className='text-left'>3.5 Kms <span style={{fontWeight:"600"}}>Kamakshi Hospital</span></li>
<li className='text-left'>5.5 Kms <span style={{fontWeight:"600"}}>Jss Hospital</span></li>
<li className='text-left'>7.5 Kms <span style={{fontWeight:"600"}}>Mysore Palace</span></li>
<li className='text-left'>2.5 Kms <span style={{fontWeight:"600"}}>JSS College And Sachidananda Ashram</span></li>
<li className='text-left'>3 Kms <span style={{fontWeight:"600"}}>Panescea Hospital</span></li>


</ul>

</div>
<h1 className='text-center mt-5 bhimaaboutus123'>Gallery </h1>
<Gallery/>


</div>

        </div>
        {/* <div>
<iframe className='bhimaaboutus123' style={{width:"100%"}} width="560" height="515" src="https://www.youtube.com/embed/jeWa-nHDznA?si=4aCsNslos1qtaD18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div> */}
<h1 className='text-center my-5 bhimaaboutus123'>Project Location</h1>

<div style={{width:"100%"}}>
<iframe   className='bhimaaboutus123'  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3898.9813859197156!2d76.65495880000002!3d12.2495388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf6f9ce331bc7d%3A0xa613b6b828007075!2sBhima%20Kritika%20Lake%20View!5e0!3m2!1sen!2sin!4v1716291325452!5m2!1sen!2sin" width="600" height="450" style={{border:"0", width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<Contact/>
<br/>
        </>
    )
}
