import React, {useState, useEffect} from 'react'
import News from '../assets/img/Our Projects.png'
import SlideProject1 from '../assets/img/Ashlesha/1.jpg';
import SlideProject2 from '../assets/img/property/home-slide-1.png';
import SlideProject3 from '../assets/img/Ashvini/090.jpg'
import SlideProject4 from '../assets/img/Krithika/3.jpg';
import SlideProject5 from '../assets/img/Punarvasu/3.jpg';
import SlideProject6 from '../assets/img/property/home-slide-1.png';
import SlideProject7 from '../assets/img/property/home-slide-1.png';
import SlideProject8 from '../assets/img/property/home-slide-1.png';
import SlideProject9 from '../assets/img/property/home-slide-1.png';
import SlideProject10 from '../assets/img/property/bhima-vishaka-slide-11.jpg';
import SlideProject11 from '../assets/img/property/bhima-sindhoor-slide-2.jpg'
import SlideProject12 from '../assets/img/property/home-slide-1.png';
import SlideProject13 from '../assets/img/Ashvini/062.jpg'
import SlideProject14 from '../assets/img/property/home-slide-1.png';
import SlideProject15 from '../assets/img/property/home-slide-1.png';
import SlideProject16 from '../assets/img/property/home-slide-1.png';
import SlideProject17 from '../assets/img/property/home-slide-1.png';
import SlideProject18 from '../assets/img/af/8.jpg';
import projevt from '../assets/img/project.png';
import PointIcon1 from '../assets/img/icon/point-1.png';
import PointIcon2 from '../assets/img/icon/point-2.png';
import PointIcon3 from '../assets/img/icon/point-3.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



const Project = () => {
    const [primaryFilter, setPrimaryFilter] = useState('all'); // 'all', 'ongoing', 'completed'
    const [secondaryFilter, setSecondaryFilter] = useState('all');
    const projects = [

        {
          title: "MRIGASHIRA",
          status: "Ongoing Project",
          location: "Madaragalli Village, Mysore",
          description: "Mrigashira offers prime villa sites, just 1km off NH766 and minutes from the Mysore airport, Mysore palace and tourist attractions. Choose from 9*12, 9*15, or odd-sized ...",
          link: "/mrigashira-project",
          image: SlideProject2
        },
        {
          title: "ASHVINI PHASE 2",
          status: "Ongoing Project",
          location: "Mandakalli Village, Mysore",
          description: "Nestled near Nanjanagud NH 766 Highway, Mysore Airport, and the Mysore Palace (8.5 km), this ongoing project offers prime villa sites in various sizes (9*12, 9*15, odd) ...",
          link: "/ashvini-phase-2-project",
          image: SlideProject3
        },
        {
            title: "KRITHIKA LAKE VIEW",
            status: "Ongoing Project",
            location: "Gejjagalli Village, Mysore",
            description: "Embrace your paradise with breathtaking lake views at Krithika Lake View! Nestled just 200 meters from NH766 and minutes from Mysore Airport ...",
            link: "/krithika-lake-view-project",
            image: SlideProject4
          },
          {
            title: "PUNARVASU",
            status: "Ongoing Project",
            location: "Gejjagalli Village, Mysore",
            description: "Craft your dream villa in peaceful Gejjagalli Village, just 200m from bustling NH766 and a quick 2.5km from Mysore Airport. Punarvasu offers a limited release of ...",
            link: "/punarvasu-project",
            image: SlideProject5
          },
          {
            title: "PUNARVASU 2",
            status: "Ongoing Project",
            location: "Mandakalli Village, Mysore",
            description: "Located just 500 meters from NH766 in Mandakalli village, Punarvasu 2 whispers an invitation to build your own retreat. Choose from a select offering of diverse ...",
            link: "/punarvasu-2-project",
            image: SlideProject6
          },
          {
            title: "KRITHIKA PHASE 2",
            status: "Ongoing Project",
            location: "Gejjagalli Village, Mysore",
            description: "Krithika Phase-2, an ongoing project in Mysore, is MUDA approved and RERA registered. Located just 200 meters from NH 766, it offers 25 plots in dimensions ...",
            link: "/krithika-phase-2-project",
            image: SlideProject7
          },
          {
            title: "ASHVINI PHASE 3",
            status: "Upcoming Project",
            location: "Mandakalli Village, Mysore",
            description: "Prime plots await in Ashvini Phase 3, your chance to build your dream home near buzzing Mysore amenities. Choose from diverse options (6*9, 9*12, 9*odd, 12*odd) ...",
            link: "/ashvini-phase-3-project",
            image: SlideProject8
          },
          {
            title: "KRITHIKA HILL VIEW",
            status: "Upcoming Project",
            location: "Gejjagalli Village, Mysore",
            description: "Krithika Hill View, a MUDA-approved and RERA-registered upcoming project in Gejjagalli Village, Mysore, offers 82 residential plots in dimensions of 9*12 meters ...",
            link: "/krithika-hill-view-project",
            image: SlideProject9
          },
          {
            title: "BHIMA VISHAKA",
            status: "Upcoming Project",
            location: "Madaragalli Village, Mysore",
            description: "Bhima Vishaka, an upcoming project in Madaragalli Village, Mysore, offers 109 residential plots across 6 acres 3 guntas. MUDA approved and RERA ...",
            link: "/bhima-vishaka-project",
            image: SlideProject10
          },
          {
            title: "BHIMA SINDHOOR",
            status: "Upcoming Project",
            location: "Sindhuvalli Village, Mysore",
            description: "Bhima Sindhoor, an upcoming project in Sindhuvalli Village, Mysore, offers 220 residential plots across 13 acres 7 guntas. MUDA approved and RERA ...",
            link: "/bhima-sindhoor-project",
            image: SlideProject11
          },
          {
            title: "BHIMA POORVAPALGUNI",
            status: "Upcoming Project",
            location: "Yelawala Village, Mysore",
            description: "Bhima Poorvapalguni, an upcoming project in Yelawala Village, Mysore, is MUDA approved and RERA registered. Offering essential amenities like 24/7 security ...",
            link: "/bhima-Poorvapalguni-project",
            image: SlideProject12
          },
          {
            title: "ASHVINI PHASE 1",
            status: "Completed Project",
            location: "Mandakalli Village, Mysore",
            description: "Ashvini Phase 1, a completed project in Mandakalli Village, Mysore with MUDA and RERA registration. Spanning 3 acres 14 guntas, it offers 63 sites with dimensions ...",
            link: "/ashvini-phase-1-project",
            image: SlideProject13
          },
          {
            title: "BHIMA ROHINI SIGNATURE",
            status: "Completed Project",
            location: "Bogadi Village, Mysore",
            description: "Bhima Rohini Signature, a completed project in Bogadi, Mysore, offers 60 sites in dimensions of 9*12, 9*15 meters, and odd sizes across 3 acres 11 guntas ...",
            link: "/bhima-rohini-signature-project",
            image: SlideProject14
          },
          {
            title: "BHIMA BHARANI 1",
            status: "Completed Project",
            location: "Martikythanahalli Village, Mysore",
            description: "Bhima Bharani-1, a completed project in Martikythanahalli Village, Mysore, offers 53 sites in various dimensions across 3 acres and 35 guntas. With 100% sold-out ...",
            link: "/bhima-bharani-1-project",
            image: SlideProject15
          },
          {
            title: "BHIMA BHARANI 2",
            status: "Completed Project",
            location: "Martikythanahalli Village, Mysore",
            description: "Bhima Bharani-2, a concluded project in Martikythanahalli Village, Mysore, presents 179 sites in dimensions of 9*12, 9*15, 12*18 meters, and odd sizes across a vast 12 acres ...",
            link: "/bhima-bharani-2-project",
            image: SlideProject16
          },
          {
            title: "BHIMA POORVAPRATHAM",
            status: "Completed Project",
            location: "Bogadi Village, Mysore",
            description: "Bhima Poorvapratham, a completed project in Bogadi Village, Mysore, offers 44 sites in dimensions of 9*12 meters and odd sizes across 2 acres 09 guntas. With 100% sold ...",
            link: "/bhima-poorvapratham-project",
            image: SlideProject17
          },
        
        {
          title: "ALLFINE",
          status: "Completed Project",
          location: "Bogadi Village, Mysore",
          description: "Allfine, a successfully concluded project in Bogadi Village, Mysore, offers 60 sites in dimensions of 6*9, 9*12 meters, and odd sizes across 3 acres. With 100% sold-out ...",

          link: "/allfine-project",
          image: SlideProject18
        }

      ];
    
      const uniqueStatuses = [...new Set(projects.map(project => project.status))];

      useEffect(() => {
        // Reset the secondary filter when the primary filter changes
        setSecondaryFilter('all');
      }, [primaryFilter]);
      useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
  return (
  <>


<Helmet>
                <meta charSet="utf-8" />
                <title>Bhima Properties | Residential Projects in Mysore, Karnataka</title>
                <meta name="description" content="Explore Bhima Properties' latest residential developments in Mysore. Quality living spaces delivered with precision and passion across Karnataka" />
                <link rel="canonical" href="https://bhimaproperties.com/projects" />
            </Helmet>


    <img src={News} className='img-fluid w-100 news-img mb-5' />


<div className='desktop-banner'>
<div className='container' style={{ display: 'flex', gap: '2rem' }}>
<div>
    <h5>Project Status</h5>
        <select value={primaryFilter} onChange={(e) => setPrimaryFilter(e.target.value)} style={{border:"1px solid rgb(240, 126, 1)", padding:"10px", borderRadius:"10px", width:"16rem"}}>
  
          <option value="all">All Projects</option>
          {uniqueStatuses.map((status, index) => (
            <option key={index} value={status.toLowerCase()}>{status}</option>
          ))}
        </select>
      </div>

      {/* Secondary Filter Dropdown */}
      {primaryFilter !== 'all' && (
        <div>
            <h5>Project Name</h5>
          <select value={secondaryFilter} onChange={(e) => setSecondaryFilter(e.target.value)}  style={{border:"1px solid rgb(240, 126, 1)", padding:"10px", borderRadius:"10px", width:"16rem"}}>
            <option value="all">All</option>
            {projects
              .filter(project => project.status.toLowerCase() === primaryFilter)
              .map((project, index) => (
                <option key={index} value={project.title.toLowerCase()}>{project.title}</option>
              ))}
          </select>
        </div>
      )}
</div>
<Link to="/ashlesha-project" style={{ textDecoration: "none", color:"black" }}>
          <div className='container newproject'>
            <div className='row my-3' style={{ border: "1px solid #F07E01", padding: "1rem", borderRadius: "10px" }}>
              <div className='col-4 p-0 m-auto'>
                <Link to="/ashlesha-project" style={{ textDecoration: "none" }}>
                  <img src={SlideProject1} className='img-fluid property-img w-100' style={{ borderRadius: "10px" }}></img>
                </Link>
              </div>
              <div className='col-8 ' style={{ padding: "0rem 5rem" }}>
                <Link to="/ashlesha-project" style={{ textDecoration: "none", color: "black" }}><h3 style={{ fontWeight: "600" }}>ASHLESHA</h3></Link>
                <h5 style={{ color: "rgb(255, 122, 0)" }}>Ongoing Project</h5>
                <p>Ashlesha offers prime villa sites just 400m off NH766, conveniently close to the mysore airport, JP Nagar, and tourist attractions. Choose from 9*12, 9*15, or odd-sized plots ...</p>
                <h5>Location : Madaragalli Village, Mysore</h5>
                <div className='row '>
                  <div className='col-3 text-center'>
                    <div className='text-center'>
                      <Link to="/ashlesha-project" style={{ textDecoration: "none" }}>
                        <button className='btn load-more-projects w-100' style={{ borderRadius: '10px !important' }}> Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> </Link>
      {/* Display filtered projects */}
      {projects
        .filter(project => {
          if (primaryFilter === 'all') return true;
          return project.status.toLowerCase() === primaryFilter;
        })
        .filter(project => {
          if (secondaryFilter === 'all') return true;
          return project.title.toLowerCase() === secondaryFilter;
        })
        .map((project, index) => (
          <Link to={project.link} style={{ textDecoration: "none", color:"black" }}>
          <div className='container newproject bhimaaboutus123' key={index}>
            <div className='row my-3' style={{ border: "1px solid #F07E01", padding: "1rem", borderRadius: "10px" }}>
              <div className='col-4 p-0 m-auto'>
                <Link to={project.link} style={{ textDecoration: "none" }}>
                  <img src={project.image} className='img-fluid property-img w-100' style={{ borderRadius: "10px" }}></img>
                </Link>
              </div>
              <div className='col-8 ' style={{ padding: "0rem 5rem" }}>
                <Link to={project.link} style={{ textDecoration: "none", color: "black" }}><h3 style={{ fontWeight: "600" }}>{project.title}</h3></Link>
                <h5 style={{ color: "rgb(255, 122, 0)" }}>{project.status}</h5>
                <p>{project.description}</p>
                <h5>Location : {project.location}</h5>
                <div className='row '>
                  <div className='col-3 text-center'>
                    <div className='text-center'>
                      <Link to={project.link} style={{ textDecoration: "none" }}>
                        <button className='btn load-more-projects w-100' style={{ borderRadius: '10px !important' }}> Know More </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> </Link>))}
  </div>

  <div className='mobile-banner'>
  <div className='container' style={{ display: 'flex', gap: '2rem' }}>
<div>
    <h5>Project Status</h5>
        <select value={primaryFilter} onChange={(e) => setPrimaryFilter(e.target.value)} style={{border:"1px solid rgb(240, 126, 1)", padding:"10px", borderRadius:"10px", width:"10rem"}}>
  
          <option value="all">All</option>
          {uniqueStatuses.map((status, index) => (
            <option key={index} value={status.toLowerCase()}>{status}</option>
          ))}
        </select>
      </div>

      {/* Secondary Filter Dropdown */}
      {primaryFilter !== 'all' && (
        <div>
            <h5>Project Name</h5>
          <select value={secondaryFilter} onChange={(e) => setSecondaryFilter(e.target.value)}  style={{border:"1px solid rgb(240, 126, 1)", padding:"10px", borderRadius:"10px", width:"10rem"}}>
            <option value="all">All</option>
            {projects
              .filter(project => project.status.toLowerCase() === primaryFilter)
              .map((project, index) => (
                <option key={index} value={project.title.toLowerCase()}>{project.title}</option>
              ))}
          </select>
        </div>
      )}
</div>
      {/* Display filtered projects */}
      {projects
        .filter(project => {
          if (primaryFilter === 'all') return true;
          return project.status.toLowerCase() === primaryFilter;
        })
        .filter(project => {
          if (secondaryFilter === 'all') return true;
          return project.title.toLowerCase() === secondaryFilter;
        })
        .map((project, index) => (
  <div className='property-box bhimaaboutus123'>
  <Link to={project.link} style={{textDecoration:"none"}}>

     <img src={project.image} className='img-fluid property-img w-100'></img>
     </Link>
     <div className='product-description'>
     <h5 style={{ color: "rgb(255, 122, 0)" }}>{project.status}</h5>

<Link to={project.link} style={{textDecoration:"none", color:"black"}}>   <h4>{project.title}</h4> </Link>
        <p className='mb-2'>{project.description}</p>
        <div className='product-line'></div>
        <h5 className='mt-3'>Amenities</h5>
        <div className=' d-flex justify-content-center'>
            
        <div className='col-10'>
        
        <div className='row  pt-3 g-4'>
                    <div className='col-4'>
                        <img src={PointIcon1} className='img-fluid'></img>
                        <p className='pt-2 propduct-point text-center mb-0' >24×7 SECURITY AT THE LAYOUT</p>
                    </div>

                    <div className='col-4'>
                        <img src={PointIcon2} className='img-fluid'></img>
                        <p className='pt-2 propduct-point text-center mb-0' >GOOD APPROACH ROADS</p>
                    </div>

                    <div className='col-4'>
                        <img src={PointIcon3} className='img-fluid'></img>
                        <p className='pt-2 propduct-point text-center mb-0' >UNDERGROUND SEWAGE</p>
                    </div>
                </div>
        </div>
</div>
<div className='text-center'>
  <Link to={project.link}  style={{textDecoration:"none"}}>
            <button className='btn load-more-projects w-100 my-3' style={{borderRadius:'10px !important'}}> Know More </button></Link>
            </div>

     </div>
     
  </div>
))}



  </div>

  </>
  )
}

export default Project