import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MainLogo from '../assets/img/logo/main-logo.png';
import { NavLink } from 'react-router-dom';

export default function TransparentHeader() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isProjectsMenuOpen, setProjectsMenuOpen] = useState(false);
    const [isProject2MenuOpen, setProject2MenuOpen] = useState(false); // Define state for Project 2 menu

    const handleToggleClick = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLinkClick = () => {
      // Close the mobile menu when a link is clicked
      setMobileMenuOpen(false);
    };

    const handleProjectsMenuClick = () => {
      setProjectsMenuOpen(!isProjectsMenuOpen);
    };

    const handleProject2MenuClick = () => {
      setProject2MenuOpen(!isProject2MenuOpen); // Toggle Project 2 menu
    };
    useEffect(() => {
      const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
      const scrollHandler = () => {
          testimonialElements.forEach(testimonial => {
              const bounding = testimonial.getBoundingClientRect();
              if (bounding.top < window.innerHeight) {
                  testimonial.classList.add('show');
              }
          });
      };
      window.addEventListener('scroll', scrollHandler);
      // Cleanup
      return () => {
          window.removeEventListener('scroll', scrollHandler);
      };
  }, []);
    return (
      <div className='transeprent-header '>
        <div className='container'>
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
              {/* Logo on the left */} 
              <a className="navbar-brand" href="/">
                <img src={MainLogo} className='img-fluid logo-mobile' alt="Logo" />
              </a>
              <h1 className="bhima-heading">BHIMA PROPERTIES</h1>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleToggleClick}
                style={{ color:'#FFEFDF',borderColor: '#FFEFDF' }}
              >
                <span className="navbar-toggler-icon" style={{color:'#FFEFDF', borderColor: '#FFEFDF' }} />
              </button>

              <div className={`collapse navbar-collapse justify-content-between ${isMobileMenuOpen ? 'show' : ''}`}  id="navbarSupportedContent">
                {/* Search box in the center */}
                <form className="d-flex header__search--form">
                  <div className="header__search--box">
                   
                    {/* <label>
                      <input className="header__search--input" placeholder="Search" type="text" />
                
                    </label> */}
                  </div>
                </form>

                {/* Navigation on the right */}
                <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink exact to="/" className="nav-link" activeClassName="active" onClick={handleLinkClick}>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/projects" className="nav-link" activeClassName="active" onClick={handleLinkClick}>Projects</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/about-us" className="nav-link" activeClassName="active" onClick={handleLinkClick}>About Us</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to="/blogs" className="nav-link" activeClassName="active" onClick={handleLinkClick}>Blogs</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to="/contact-us" className="nav-link" activeClassName="active" onClick={handleLinkClick}>Contact Us</NavLink>
                                </li>
                            </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    )}

