import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Stars from '../assets/img/star.png';
import hi from '../assets/img/hi.png';
import customer from '../assets/img/customer.png';
import CustomPrevButton from '../assets/img/icon/arrow-back.png';
import CustomNextButton from '../assets/img/icon/arrow-next.png';

export default function HomeTestimonial() {
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.testomo-box');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    const testimonialData = [
        {
            text: 'Excellent layout, can appreciate soon. Lots of greenery and coconut trees everywhere. Very well maintained property. Would suggest to visit once if you want to buy and property from this builder.',
            name: 'Naveen R',
            designation: '',
        },
        {
            text: 'Great atmosphere well maintained layout with all amenities, Layout looks fresh with all newly built homes and not to mention the great reputation and the trust in BHIMA.',
            name: 'Thejas',
            designation: '',
        },
        {
            text: 'Beautiful layout with greenary around. Calm and peaceful. Excellent residential layout with good facilities like Daily water, open parking space, well maintained parks, good roads, near by school, hospital and provision stores.',
            name: 'Manjunath Nagaraju',
            designation: '',
        }
        // Add more testimonial objects as needed
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        prevArrow: <img src={CustomPrevButton} alt="Prev" className="custom-prev-button" />,
        nextArrow: <img src={CustomNextButton} alt="Next" className="custom-next-button" />,
    };

    return (
        <div className='testo-carosal pt-5' style={{ background: `url(${customer})`, width: "100%", backgroundSize: "cover" }}>
            <div className='container'>
                <h2 style={{ color: '#fff', textAlign: 'center' }}>Happy Customers</h2>
                <div className='testo-slide'>
                    <Slider className='home-testo-slide' {...settings}>
                        {testimonialData.map((testimonial, index) => (
                            <div className='testomo-box' key={index}>
                                <div>
                                    <img src={hi} style={{ margin: "auto", width: "12rem" }} />
                                </div>
                                <p style={{ color: "white", margin: "3rem 0" }}>{testimonial.text}</p>
                                <div><img src={Stars} style={{ margin: "auto" }} /></div>
                                <p className='mb-0 mt-3' style={{ fontSize: '16px !important', color: '#FFB602', fontWeight: '500' }}>
                                    {testimonial.name}
                                </p>
                                <p style={{ fontSize: '14px !important', color: '#FFF' }}>{testimonial.designation}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
