import React, {useEffect} from 'react'
import FooterSubscribe from '../components/FooterSubscribe';
import HomeFaq from '../components/HomeFaq'
import HomeNews from '../components/HomeNews'
import HomeContact from '../components/HomeContact';
import { Helmet } from 'react-helmet';
import Blog from '../components/Blog';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">

<Helmet>
                <meta charSet="utf-8" />
                <title>Real Estate Insights | Bhima Properties Blog - Mysore, Karnataka</title>
                <meta name="description" content="Stay informed about property investment, construction trends, and real estate updates in Mysore with Bhima Properties' expert insights" />
                <link rel="canonical" href="https://bhimaproperties.com/blogs" />
            </Helmet>
  <Blog/>
    <HomeContact/>
    <HomeFaq/>
   
  {/* <FooterSubscribe/> */}
   
  </div>
  )
}

export default Blogs