import React, { useEffect, useState } from 'react';

const useAccordion = () => {
  const [activeKey, setActiveKey] = React.useState(null);

  const handleAccordionChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return {
    activeKey,
    handleAccordionChange,
  };
};

const HomeFaq = () => {
  const accordionHook1 = useAccordion();
  const accordionHook2 = useAccordion();
  useEffect(() => {
    const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
    const scrollHandler = () => {
        testimonialElements.forEach(testimonial => {
            const bounding = testimonial.getBoundingClientRect();
            if (bounding.top < window.innerHeight) {
                testimonial.classList.add('show');
            }
        });
    };
    window.addEventListener('scroll', scrollHandler);
    // Cleanup
    return () => {
        window.removeEventListener('scroll', scrollHandler);
    };
}, []);
  return (
    <div className='container bhimaaboutus123'>
      <div className='home-faq pb-4' >
        <h2 style={{ color: '#000000', textAlign: 'center', padding:'25px 0px' }}>FAQ’S</h2>

        <div className='d-flex justify-content-center'>
          <div className="row col-lg-10 col-md-11 col-12  py-4">
            <div className="col-md-6 col-12">
              <div className="accordion" id="accordionExample1">
                {/* Your first accordion items */}
                {accordionData1.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading1_${index}`}>
                      <button
                        className={`accordion-button ${accordionHook1.activeKey === index.toString() ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => accordionHook1.handleAccordionChange(index.toString())}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse1_${index}`}
                      className={`accordion-collapse collapse ${accordionHook1.activeKey === index.toString() ? 'show' : ''}`}
                      aria-labelledby={`heading1_${index}`}
                      data-parent="#accordionExample1"
                    >
                      <div className="accordion-body">{item.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="accordion" id="accordionExample2">
                {/* Your second accordion items */}
                {accordionData2.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading2_${index}`}>
                      <button
                        className={`accordion-button ${accordionHook2.activeKey === index.toString() ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => accordionHook2.handleAccordionChange(index.toString())}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse2_${index}`}
                      className={`accordion-collapse collapse ${accordionHook2.activeKey === index.toString() ? 'show' : ''}`}
                      aria-labelledby={`heading2_${index}`}
                      data-parent="#accordionExample2"
                    >
                      <div className="accordion-body">{item.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFaq;

const accordionData1 = [
  {
    question: 'How do I get in touch with you?',
    answer: 'you can call us at 9741988821 / 0821-2458821',
  },
  {
    question: 'What locations are your project situated at?',
    answer: 'in mysore near JP nagar , srinagar , bogadi and ring road',
  },
  {
    question: 'Do you have loan facility?',
    answer: 'yes, our projects are approved by most of the major banks .',
  },
  {
    question: 'Are Prices Negotiable?',
    answer: 'YES , Prices are Negotiable.',
  },

  // Add more accordion items for the first accordion
];

const accordionData2 = [
  {
    question: 'What type of katha?',
    answer: 'MUDA Katha',
  },
  {
    question: 'Is it gated community?',
    answer: 'No , it’s a non gated community .',
  },
  {
    question: 'How To Check If A Project Is RERA Registered?',
    answer: `To check the RERA registration status of a real estate project, you can visit: https://rera.karnataka.gov.in/
 `,
  },



  {
    question: 'What amenities are included in your projects?',
    answer: `Our projects typically include amenities such as landscaped gardens, children's play areas, and 24/7 security. Specific amenities may vary by project.
 `,
  }
  // Add more accordion items for the second accordion
];
