import React, { useEffect, useState } from 'react';
import HomeFaq from './HomeFaq'
import HomeNews from './HomeNews'
import HomeTestimonial from './HomeTestimonial'
import HomeContact from './HomeContact';
import HomeProjects from './HomeProjects';
import HomeAboutus from './HomeAboutus';

export default function HomeSectionsBind() {
  return (
    <div className='home-section-bind'>
 <HomeProjects/>
 <HomeAboutus/>
         <HomeTestimonial/>
         <HomeContact/>
        <HomeFaq/>
        {/* <HomeNews/> */}
       
    </div>
  )
}
