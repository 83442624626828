import React, { useEffect, useState } from 'react';
import News from '../assets/img/News_and_Articles.png'
import News1 from '../assets/img/news/1.png';
import News2 from '../assets/img/news/2.png';
import News3 from '../assets/img/news/3.png';
import News4 from '../assets/img/news/4.png';
import ReadMoreImg from '../assets/img/icon/east.png';
const Blog = () => {
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
  return (
    <>
    <img src={News} className='img-fluid w-100 news-img mb-5' />
    <div className='container desktop-banner'>

<div className='row py-4'>
    <div className='col-md-6 col-12'>
        <div className='news-box'>
            <div className='row g-0'>
                <div className='col-md-5 col-12'>
                    <img src={News1} className='img-fluid w-100 news-img' />
                </div>
                <div className='col-md-7 col-12 news-content-box'>
              <div className='news-content'>
              <h5>Check Out Recent News & Articles...</h5>
                    <p className='mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="row">
                        <div class="col-md-6">
                            <p class="mb-0 date">Jan 02, 2023</p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="mb-0 read-more" style={{color:"#F07E01"}}>
                                Read More
                               <img src={ReadMoreImg} className='img-fluid mx-2' />
                            </p>
                        </div>
                    </div>
              </div>

                </div>
            </div>
        </div>

        <div className='news-box bhimaaboutus123'>
            <div className='row g-0'>
                <div className='col-md-5 col-12'>
                    <img src={News3} className='img-fluid w-100 news-img' />
                </div>
                <div className='col-md-7 col-12 news-content-box'>
              <div className='news-content'>
              <h5>Check Out Recent News & Articles...</h5>
                    <p className='mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="row">
                        <div class="col-md-6">
                            <p class="mb-0 date">Jan 02, 2023</p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="mb-0 read-more" style={{color:"#F07E01"}}>
                                Read More
                               <img src={ReadMoreImg} className='img-fluid mx-2' />
                            </p>
                        </div>
                    </div>
              </div>

                </div>
            </div>
        </div>
    </div>


    <div className='col-md-6 col-12'>
        <div className='news-box'>
            <div className='row g-0'>
                <div className='col-md-5 col-12'>
                    <img src={News2} className='img-fluid w-100 news-img' />
                </div>
                <div className='col-md-7 col-12 news-content-box'>
              <div className='news-content'>
              <h5>Check Out Recent News & Articles...</h5>
                    <p className='mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="row">
                        <div class="col-md-6">
                            <p class="mb-0 date">Jan 02, 2023</p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="mb-0 read-more" style={{color:"#F07E01"}}>
                                Read More
                               <img src={ReadMoreImg} className='img-fluid mx-2' />
                            </p>
                        </div>
                    </div>
              </div>

                </div>
            </div>
        </div>

        <div className='news-box bhimaaboutus123'>
            <div className='row g-0'>
                <div className='col-md-5 col-12'>
                    <img src={News4} className='img-fluid w-100 news-img' />
                </div>
                <div className='col-md-7 col-12 news-content-box'>
              <div className='news-content'>
              <h5>Check Out Recent News & Articles...</h5>
                    <p className='mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="row">
                        <div class="col-md-6">
                            <p class="mb-0 date">Jan 02, 2023</p>
                        </div>
                        <div class="col-md-6 text-end">
                            <p class="mb-0 read-more" style={{color:"#F07E01"}}>
                                Read More
                               <img src={ReadMoreImg} className='img-fluid mx-2' />
                            </p>
                        </div>
                    </div>
              </div>

                </div>
            </div>
        </div>
    </div>


 
</div>
</div>
<div className='mobile-banner'>
<div className='py-4'>
    <div>
    <div className='news-box' style={{border:"1px solid", padding:"2rem 1rem 0rem 1rem", borderRadius:"10px"}}>
            <div className='row g-0'>
                <div className='col-5'>
                    <img src={News1} className='img-fluid w-100 news-img' />
                </div>
                <div className='col-7 news-content-box'>
              <div className='news-content'>
              <h5>Check Out Recent News & Articles...</h5>
                    <p className='mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="row">
                    
                            <p class="mb-0 date">Jan 02, 2023</p>
                      <br/>
                            <p class="mb-0 read-more" style={{color:"#F07E01"}}>
                                Read More
                               <img src={ReadMoreImg} className='img-fluid mx-2' />
                            </p>
                        
                    </div>
              </div>

                </div>
            </div>
        </div>

        <div className='news-box' style={{border:"1px solid", padding:"2rem 1rem 0rem 1rem", borderRadius:"10px"}}>
            <div className='row g-0'>
            <div className='col-5'>
                    <img src={News3} className='img-fluid w-100 news-img' />
                </div>
                <div className='col-7 news-content-box'>
              <div className='news-content'>
              <h5>Check Out Recent News & Articles...</h5>
                    <p className='mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="row">
                    
                    <p class="mb-0 date">Jan 02, 2023</p>
              <br/>
                    <p class="mb-0 read-more" style={{color:"#F07E01"}}>
                        Read More
                       <img src={ReadMoreImg} className='img-fluid mx-2' />
                    </p>
                
            </div>
              </div>

                </div>
            </div>
        </div>
    </div>




 
</div>
</div>
</>
  )
}

export default Blog