import React, {useEffect} from 'react'
import FooterSubscribe from '../components/FooterSubscribe';
import Project from '../components/Project3';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
 
   <Project/>
  {/* <FooterSubscribe/> */}
   
  </div>
  )
}

export default Blogs