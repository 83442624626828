
import '../App.css';
import HomeSectionsBind from '../components/HomeSectionsBind';
import FooterSubscribe from '../components/FooterSubscribe';
import Footer from '../components/Footer';
import HomeProjects from '../components/HomeProjects';
import TransparentHeader from '../components/TransparentHeader';
import HomeHeroSection from '../components/HomeHeroSection';
import Contact from '../components/HomeContactpopup'
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import ProjectPopup from '../components/ProjectPopup';
import HomeHeroBanner from '../components/HomeHeroBanner';
import { Helmet } from "react-helmet";

function Home() {
  const img = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/IZB+Website+Skin/Home_Page/Image_3Short.png"
  const img1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/20x4-Invitation-to-Tender-(1).jpg"
  const img2 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/clear.svg"
 const [isImageModalOpen, setImageModalOpen] = useState(false);
 useEffect(() => {
  const hasModalOpenedBefore = localStorage.getItem('modalOpened');
  if (!hasModalOpenedBefore) {
    const timer = setTimeout(() => {
      setImageModalOpen(true);
      localStorage.setItem('modalOpened', true);
    }, 10000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  } else {
    setImageModalOpen(false); // Close the modal if it has been opened before
  }
}, []);

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

const closeModal = () => {
  setImageModalOpen(false);
};
  return (
    <>

<Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://bhimaproperties.com" />
<title>Bhima Properties | Premier Real Estate Developers in Mysore, Karnataka</title>
<meta name="description" content="Discover high-quality residential projects with Bhima Properties, Mysore's leading real estate developer. Enhance your living experience in Karnataka" />
</Helmet>









    <div className="App">
      <HomeHeroBanner/>
      {/* <HomeHeroSection/> */}
      {/* <HomeProjects/> */}
     
      <HomeSectionsBind/>
      {/* <FooterSubscribe/> */}
     
    </div>
    {/* <Modal
    // className='container'
    // isOpen={isImageModalOpen}
    // onRequestClose={() => setImageModalOpen(false)}
    className='container'
        isOpen={isImageModalOpen}
        onRequestClose={closeModal}
    contentLabel="Image Popup"
    style={{
      overlay: {
        zIndex: 9999999999999999 // Adjust this value as needed
      },
    }}
  >




   <Contact/>
   
  </Modal> */}

  <ProjectPopup/>
  </>
  );
}

export default Home;