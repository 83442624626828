import React, { useEffect, useState } from 'react';
import bannerImage3 from '../assets/img/banner/m14.png'
import bannerImage1 from '../assets/img/banner/d14.png'
import ame1 from '../assets/img/am/am1.png'
import ame2 from '../assets/img/am/am2.png'
import ame3 from '../assets/img/am/am3.png'
import ame4 from '../assets/img/am/am4.png'
import ame5 from '../assets/img/am/am5.png'
import ame6 from '../assets/img/am/am6.png'
import ame7 from '../assets/img/am/am7.png'
import ame8 from '../assets/img/am/am8.png'
import ame9 from '../assets/img/am/am9.png'
import ame11 from '../assets/img/am/am11.png'
import { Link } from 'react-router-dom'
import Gallery from '../components/HomeProjects1'
import { Helmet } from 'react-helmet';

export default function HomeNews() {
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
    return (
        <>

<Helmet>
                <meta charSet="utf-8" />
                <title>Bhima Rohini Signature | MUDA-Approved Plots in Bogadi | Bhima Properties</title>
                <meta name="description" content="Discover 60 MUDA-approved plots at Bhima Rohini Signature in Bogadi, Mysore. Ideal for a well-connected home near Dattagalli Ring Road." />
                <link rel="canonical" href="https://bhimaproperties.com/bhima-rohini-signature-project" />
            </Helmet>



        <div>
           
             <img src={bannerImage1} alt="Banner 1" className='desktop-banner' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

<img src={bannerImage3} alt="Banner 1" className='mobile-banner' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>

        <div className='container my-3'>
            <h1 className='text-center my-5 aboutushover'>BHIMA ROHINI SIGNATURE </h1>
            <h5 className='bhimaaboutus123' >Bhima Rohini Signature, a completed project in Bogadi, Mysore, offers 60 sites in dimensions of 9*12, 9*15 And Odd (mts) Sites across 3 acres 11 guntas. Located near Dattagalli Ring Road, Rajarajeshwari Nagar, and key landmarks, the development is MUDA approved. </h5>
            <br/>
            <h5 className='bhimaaboutus123' ><strong>Site Dimension :</strong> 9*12, 9*15 And Odd (mts) Sites</h5>
            <h5 className='bhimaaboutus123' ><strong>Total Area :</strong> 3 Acres 11 Guntas</h5>
            <h5 className='bhimaaboutus123' ><strong>Total Sites :</strong> 60 Sites</h5>
            <h5 className='bhimaaboutus123' ><strong>100% Sold</strong></h5>
            <br/>

            <h5 className='bhimaaboutus123' ><strong>Location :</strong>Bogadi, Mysore</h5>

            <h5 className='bhimaaboutus123' ><strong>Muda Approved</strong></h5>
            <br/>
<h1 className='text-center my-5 bhimaaboutus123'>Amenities</h1>
<div className='container bhimaaboutus123'>
<div className='row' style={{border:"1px solid rgb(242, 223, 189)", background:"rgb(242, 223, 189)", padding:"3rem",borderRadius:"1rem"}}>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame1} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>24/7 Security</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame2} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>24/7 Water Supply</h6>
        </div>
 
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame4} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Avenue Lined Trees</h6>
        </div>
    
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame6} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>LandScaped Gardens</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame7} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Wide Asphalted Roads</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame8} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Jogging Track in the Parks</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame9} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>U shaped Drainage System</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame3} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Electricity supply with Street Lights</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame5} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Underground Sewage system for each Plot</h6>
        </div>
</div>

<h1 className='text-center my-5 bhimaaboutus123'>Nearby Locations </h1>
<div className='container bhimaaboutus123'>

<ul style={{fontSize:"1.5rem"}}>
<li className='text-left'>400 Meters from <span style={{fontWeight:"600"}}>Dattagalli Ring Road</span></li>
    <li>Attached to <span style={{fontWeight:"600"}}>Rajarajeshwari Nagar</span></li>
    <li>1 km From <span style={{fontWeight:"600"}}>SAA RAA Convention Hall</span></li>
    <li>1.5 Kms from <span style={{fontWeight:"600"}}>Andolana Circle</span></li>
    <li>400 Meters from <span style={{fontWeight:"600"}}>christ Public school</span></li>
    <li>6 Km from <span style={{fontWeight:"600"}}>Mysore Palace</span></li>
    <li>Near <span style={{fontWeight:"600"}}>Nethaji Circle , Rajarajeshwari Nagar, Dattagalli , Ramkrishnagar & Bogadi</span></li>


</ul>

</div>
<h1 className='text-center mt-5 bhimaaboutus123'>Gallery </h1>
<Gallery/>


</div>

        </div>
        {/* <div>
<iframe className='bhimaaboutus123'  style={{width:"100%"}} width="560" height="515" src="https://www.youtube.com/embed/jeWa-nHDznA?si=4aCsNslos1qtaD18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div> */}
<h1 className='text-center my-5 bhimaaboutus123'>Project Location</h1>

<div style={{width:"100%"}}>
<iframe  className='bhimaaboutus123'    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15598.006034902635!2d76.64617879111118!3d12.214284857772533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf6f4597972ca7%3A0xac20d611e3347bac!2sMadaragalli%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1706788894868!5m2!1sen!2sin" width="600" height="450" style={{border:"0", width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

        </>
    )
}
