import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import bannerImage3 from '../assets/img/Artboard 1.png';
import bannerImage4 from '../assets/img/Artboard 2.png';
import bannerImage5 from '../assets/img/Artboard 3.png';
import bannerImage6 from '../assets/img/Artboard 4.png';
import mobileBannerImage1 from '../assets/img/Artboard 1-mobile.png'; 
import mobileBannerImage2 from '../assets/img/Artboard 2-mobile.png';
import mobileBannerImage3 from '../assets/img/Artboard 3-mobile.png';
import mobileBannerImage4 from '../assets/img/Artboard 4-mobile.png';
import tabletBannerImage1 from '../assets/img/Artboard 1-tablet.png'; // Tablet-specific images
import tabletBannerImage2 from '../assets/img/Artboard 2-tablet.png';
import tabletBannerImage3 from '../assets/img/Artboard 3-tablet.png';
import tabletBannerImage4 from '../assets/img/Artboard 4-tablet.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function HomeHeroBanner() {
  const [screenSize, setScreenSize] = useState('desktop');

  // Detect if screen size is mobile, tablet, or desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenSize('mobile');
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
        setScreenSize('tablet');
      } else {
        setScreenSize('desktop');
      }
    };

    handleResize(); // Check the screen size on initial render
    window.addEventListener('resize', handleResize); // Update on screen resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Images for desktop and mobile
  const desktopImages = [bannerImage3, bannerImage4, bannerImage5, bannerImage6];
  const tabletImages = [tabletBannerImage1, tabletBannerImage2, tabletBannerImage3, tabletBannerImage4];
  const mobileImages = [mobileBannerImage1, mobileBannerImage2, mobileBannerImage3, mobileBannerImage4];

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  // Choose images based on whether the screen is mobile or desktop
  const images =
  screenSize === 'mobile' ? mobileImages :
  screenSize === 'tablet' ? tabletImages :
  desktopImages;

  return (
    <>
      <Link to="/contact-us">
        <div className="container-fluid my-2">
          <div className="d-flex justify-content-center">
            <div className="slider-container" style={{ width: '100%' }}>
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Hero Banner ${index + 1}`}
                      className="hero-banner"
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </Link>
      <div className="container desktop-banner" style={{ marginTop: '-90px', paddingBottom: '120px' }}>
        <Link to="/contact-us"></Link>
      </div>
      <div className="container mobile-banner" style={{ paddingBottom: '60px', paddingTop: '20px' }}>
        <div className="d-flex justify-content-center">
          <Link to="/contact-us"></Link>
          <Link to="/bhima-vishaka-project"></Link>
        </div>
      </div>
    </>
  );
}