import React, { useEffect, useState } from 'react';
import bannerImage3 from '../assets/img/banner/m1.png'
import bannerImage1 from '../assets/img/banner/d1.png'
import ame1 from '../assets/img/am/am1.png'
import ame2 from '../assets/img/am/am2.png'
import ame3 from '../assets/img/am/am3.png'
import ame4 from '../assets/img/am/am4.png'
import ame5 from '../assets/img/am/am5.png'
import ame6 from '../assets/img/am/am6.png'
import ame7 from '../assets/img/am/am7.png'
import ame8 from '../assets/img/am/am8.png'
import ame9 from '../assets/img/am/am9.png'
import ame11 from '../assets/img/am/am11.png'
import { Link } from 'react-router-dom'
import Gallery from '../components/gallery1'
import Contact from '../components/HomeContact'
import { Helmet } from 'react-helmet';


export default function HomeNews() {
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
    return (
        <>

<Helmet>
                <meta charSet="utf-8" />
                <title>Ashlesha Project | Premium Plots in Mysore | Bhima Properties</title>
                <meta name="description" content="Secure your dream plot at Ashlesha Project in Mysore by Bhima Properties. Limited availability of premium residential plots. Book now!" />
                <link rel="canonical" href="https://bhimaproperties.com/ashlesha-project" />
            </Helmet>



        <div>
            <Link to="/contact-us">
             <img src={bannerImage1} alt="Banner 1" className='desktop-banner' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

<img src={bannerImage3} alt="Banner 1" className='mobile-banner' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></Link>
        </div>

        <div className='container my-3'>
            <h1 className='text-center my-5 aboutushover '>ASHLESHA</h1>
            <h5 className='bhimaaboutus123'>Own your dream plot in Mysore! </h5>
            <h5 className='bhimaaboutus123'>Ashlesha offers prime villa sites just 400m off NH766, conveniently close to the mysore airport, JP Nagar, and tourist attractions. Choose from 9*12, 9*15 And Odd (mts) Sites plots in our serene community with 70% already sold. </h5>
            <h5 className='bhimaaboutus123'>Enjoy top amenities like 24/7 security, landscaped gardens, wide roads, and more. Muda approved and RERA registered - Invest in your future today!</h5>
            <br/>
            <h5 className='bhimaaboutus123'><strong>Site Dimension :</strong> 9*12, 9*15 And Odd (mts) Sites</h5>
            <h5 className='bhimaaboutus123'><strong>Total Area :</strong> 3 Acres 35 Guntas</h5>
            <h5 className='bhimaaboutus123'><strong>Total Sites :</strong> 65 Sites</h5>
            <h5 className='bhimaaboutus123'><strong>70% Sold & 30% Available</strong></h5>
            <br/>

            <h5 className='bhimaaboutus123'><strong>Location :</strong>Madaragalli Village, Mysore</h5>

            <h5 className='bhimaaboutus123'><strong>Muda Approved & RERA Registered</strong></h5>
            <br/>
<h1 className='text-center my-5 bhimaaboutus123'>Amenities</h1>
<div className='container bhimaaboutus123'>
<div className='row' style={{border:"1px solid rgb(242, 223, 189)", background:"rgb(242, 223, 189)", padding:"3rem",borderRadius:"1rem"}}>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame1} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>24/7 Security</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame2} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>24/7 Water Supply</h6>
        </div>
 
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame4} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Avenue Lined Trees</h6>
        </div>
    
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame6} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>LandScaped Gardens</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame7} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Wide Asphalted Roads</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame8} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Jogging Track in the Parks</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame9} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>U shaped Drainage System</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame3} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Electricity supply with Street Lights</h6>
        </div>
        <div className='col-4 text-center my-3 aboutushover'>
        <img src={ame5} alt="Banner 1" style={{objectFit: 'cover', width:"5rem" }} />  
        <h6 className='mt-3'>Underground Sewage system for each Plot</h6>
        </div>
</div>

<h1 className='text-center my-5 bhimaaboutus123'>Nearby Locations </h1>
<div className='container bhimaaboutus123'>

<ul style={{fontSize:"1.5rem"}}>
    <li className='text-left'>400 Meters from <span style={{fontWeight:"600"}}>NH766 Mysore Nanjangud Road</span></li>
    <li>800 Meters before <span style={{fontWeight:"600"}}>Nanjanagud Toll</span></li>
    <li>1 km From <span style={{fontWeight:"600"}}>Mysore Airport</span></li>
    <li>4 kms from <span style={{fontWeight:"600"}}>JP Nagar Ring Road (APMC Yard)</span></li>
    <li>10 Kms from <span style={{fontWeight:"600"}}>Mysore Palace</span></li>
    <li>7.5 Kms from <span style={{fontWeight:"600"}}>JSS College And Sachidananda Ashram</span></li>
    <li>2.5 kms before <span style={{fontWeight:"600"}}>Kadakola and adakanahalli Industrial area</span></li>
    <li>8 kms form <span style={{fontWeight:"600"}}>Maharshi Public school</span></li>

</ul>

</div>
<h1 className='text-center mt-5 bhimaaboutus123'>Gallery </h1>
<Gallery/>


</div>

        </div>
        {/* <div>
<iframe className='bhimaaboutus123' style={{width:"100%"}} width="560" height="515" src="https://www.youtube.com/embed/jeWa-nHDznA?si=4aCsNslos1qtaD18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div> */}
<h1 className='text-center my-5 bhimaaboutus123'>Project Location</h1>

<div style={{width:"100%"}}>
<iframe  className='bhimaaboutus123'   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15598.006034902635!2d76.64617879111118!3d12.214284857772533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf6f4597972ca7%3A0xac20d611e3347bac!2sMadaragalli%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1706788894868!5m2!1sen!2sin" width="600" height="450" style={{border:"0", width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<Contact/>
<br/>
        </>
    )
}
