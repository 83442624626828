import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomPrevButton from '../assets/img/icon/left-arrow1.png';
import CustomNextButton from '../assets/img/icon/right-arrow1.png'

import SlideProject1 from '../assets/img/property/bhima-sindhoor-slide-1.jpg';
import SlideProject2 from '../assets/img/property/bhima-sindhoor-slide-2.jpg';
import SlideProject3 from '../assets/img/property/bhima-sindhoor-slide-3.jpg';
import SlideProject4 from '../assets/img/property/bhima-sindhoor-slide-4.jpg';
import SlideProject5 from '../assets/img/property/bhima-sindhoor-slide-5.jpg';
import SlideProject6 from '../assets/img/property/bhima-sindhoor-slide-6.jpg';
import SlideProject7 from '../assets/img/property/bhima-sindhoor-slide-7.jpg';
import SlideProject8 from '../assets/img/property/bhima-sindhoor-slide-8.jpg';
import SlideProject9 from '../assets/img/property/bhima-sindhoor-slide-9.jpg';
import SlideProject10 from '../assets/img/property/bhima-sindhoor-slide-10.jpg';
import SlideProject11 from '../assets/img/property/bhima-sindhoor-slide-11.jpg';
import SlideProject12 from '../assets/img/property/bhima-sindhoor-slide-12.jpg';
import SlideProject13 from '../assets/img/property/bhima-sindhoor-slide-13.jpg';
import SlideProject14 from '../assets/img/property/bhima-sindhoor-slide-14.jpg';
import SlideProject15 from '../assets/img/property/bhima-sindhoor-slide-15.jpg';
import SlideProject16 from '../assets/img/property/bhima-sindhoor-slide-16.jpg';
import SlideProject17 from '../assets/img/property/bhima-sindhoor-slide-17.jpg';

export default function BhimaSindhoorGallery() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
  autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        prevArrow: <img src={CustomPrevButton} style={{color:"black"}} alt="Prev" className="custom-prev-button" />,
        nextArrow: <img src={CustomNextButton} alt="Next" className="custom-next-button" />,
    };
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    return (
    <div className='resize-gallery'>
        <div className='home-rojects ' style={{marginTop:'-30px'}}>
            <div>
                <br/>    
                <div className='d-flex justify-content-center py-4 bhimaaboutus123'>
                    <div className='projects-slide col-lg-10 col-md-11 col-12 '>
                        <Slider className='home-projects-slide' {...settings}>
                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject1} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>
                            
                            <div className='property-box' style={{borderRadius:"20px"}} >
                                <img src={SlideProject2} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject3} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject4} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img> 
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject5} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>
                            
                            <div className='property-box' style={{borderRadius:"20px"}} >
                                <img src={SlideProject6} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject7} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject8} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img> 
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject9} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>
                            
                            <div className='property-box' style={{borderRadius:"20px"}} >
                                <img src={SlideProject10} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject11} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject12} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img> 
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject13} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>
                            
                            <div className='property-box' style={{borderRadius:"20px"}} >
                                <img src={SlideProject14} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject15} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>

                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject16} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img> 
                            </div>
                            
                            <div className='property-box' style={{borderRadius:"20px"}}>
                                <img src={SlideProject17} className='img-fluid property-img w-100' style={{borderRadius:"20px"}} alt="bhima vishaka property banner"></img>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
  )
}
