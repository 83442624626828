import React from 'react'
import logo6 from '../assets/img/Bhima_thank_you.jpg'
import { Link } from 'react-router-dom'
function Thankyou() {
  return (
    <div className='container'>
    <Link to="/">
    <img src={logo6} className='img-fluid w-100 news-img' /></Link>
    </div>
  )
}

export default Thankyou