import React, { useEffect, useState } from 'react';
import News from '../assets/img/12345678.png'
import { FaCircleArrowRight } from "react-icons/fa6";
import { Helmet } from 'react-helmet';


const Blog = () => {
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
  return (
    <>


<Helmet>
                <meta charSet="utf-8" />
                <title>Why Invest in Mysore Real Estate | Bhima Properties</title>
                <meta name="description" content="Discover the potential of real estate investment in Mysore with Bhima Properties. Learn why Mysore is an ideal location for property investment" />
                <link rel="canonical" href="https://bhimaproperties.com/why-invest-in-mysore" />
            </Helmet>







    <img src={News} className='img-fluid w-100 news-img mb-5' />
    <div className='container'>

<div className='row py-4 text-center'>

      
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}>
        <FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> Mysuru - Nanjangud to be twin city of Bangalore
        </h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> Mangalore and Mysuru to be next tech hub in Karnataka</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> HCL plans to establish an OSAT facility (chip packaging unit)</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> MoU signed between Govt. of Karnataka and Israel-based
International Semiconductor Consortium ISMC Analog Fab
Private Limited for setting up Semiconductor Fab Plant signed for
Rs. 22900 crores</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> Expansion of airport to improve connectivity for long-haul flight</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> Mysuru - Kushalnagara four lane highway and railway line
innaugrated</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> 10 Lane Expressway has cut down travel time to Bengaluru to
just 1.5 hrs.</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> Preipheral Ring Road project will enhance Mysuru's pace of
development.</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> Mysuru City Corporation is going to be 'Greater Mysore' (Bruhat
Mysuru Mahanagarapalike).</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> The IT Sector is expanding Beyond Bengaluru. Various MNCs will
have their campus at Mysuru.</h5>
        <h5 style={{textAlign:"left", marginBottom:"1.5rem"}}><FaCircleArrowRight style={{color:"rgb(240, 126, 1)"}}/> A Multimodal Logistics Park is being set up which will boost the
Industrial Growth.</h5>

     




 
</div>
</div>

</>
  )
}

export default Blog