import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import MainHeader from '../src/components/TransparentHeader';
import Footer from '../src/components/Footer';
import Home from '../src/pages/Home';
import AboutUs from '../src/pages/Aboutus';
import Projects from '../src/pages/Projects';
import Blogs from '../src/pages/Blogs';
import Blogs1 from '../src/pages/Blogs1';
import Contactus from '../src/pages/Contactus';
import Project1 from '../src/pages/Ashlesha';
import Project2 from '../src/pages/Mrigashira';
import Project3 from '../src/pages/Ashvini-phase-2';
import Project4 from '../src/pages/Krithika-lake-view';
import Project5 from '../src/pages/Punarvasu';
import Project6 from '../src/pages/Punarvasu-2';
import Project7 from '../src/pages/Krithika-phase-2';
import Project8 from '../src/pages/Ashvini-phase-3';
import Project9 from '../src/pages/Krithika-hill-view';
import Project10 from '../src/pages/Bhima-vishaka';
import Project11 from '../src/pages/Bhima-sindhoor';
import Project12 from '../src/pages/Bhima-Poorvapalguni';
import Project13 from '../src/pages/Ashvini-phase-1';
import Project14 from '../src/pages/Bhima-rohini-signature';
import Project15 from '../src/pages/Bhima-bharani-1';
import Project16 from '../src/pages/Bhima-bharani-2';
import Project17 from '../src/pages/Bhima-poorvapratham';
import Project18 from '../src/pages/Allfine';
import Thankyou from '../src/pages/Thankyou';
import Privacypolicy from './pages/Privacypolicy';


function App() {
  return (
    <div className="App">
      <>

      <Router>
     
        <MainHeader />
        <Routes>
          <Route  path="/" element={<Home/>} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/blogs" element={<Blogs/>} />
          <Route path="/why-invest-in-mysore" element={<Blogs1/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/contact-us" element={<Contactus/>} />
          <Route path="/ashlesha-project" element={<Project1/>} />
          <Route path="/mrigashira-project" element={<Project2/>} />
          <Route path="/ashvini-phase-2-project" element={<Project3/>} />
          <Route path="/krithika-lake-view-project" element={<Project4/>} />
          <Route path="/punarvasu-project" element={<Project5/>} />
          <Route path="/punarvasu-2-project" element={<Project6/>} />
          <Route path="/krithika-phase-2-project" element={<Project7/>} />
          <Route path="/ashvini-phase-3-project" element={<Project8/>} />
          <Route path="/krithika-hill-view-project" element={<Project9/>} />
          <Route path="/bhima-vishaka-project" element={<Project10/>} />
          <Route path="/bhima-sindhoor-project" element={<Project11/>} />
          <Route path="/bhima-Poorvapalguni-project" element={<Project12/>} />
          <Route path="/ashvini-phase-1-project" element={<Project13/>} />
          <Route path="/bhima-rohini-signature-project" element={<Project14/>} />
          <Route path="/bhima-bharani-1-project" element={<Project15/>} />
          <Route path="/bhima-bharani-2-project" element={<Project16/>} />
          <Route path="/bhima-poorvapratham-project" element={<Project17/>} />
          <Route path="/allfine-project" element={<Project18/>} />
          <Route path="/thank-you" element={<Thankyou/>} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />

      
        </Routes>
        <Footer />
      </Router>

      </>
    </div>
  );
}

export default App;
