import React, {useState, useEffect} from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomPrevButton from '../assets/img/icon/left-arrow1.png';
import CustomNextButton from '../assets/img/icon/right-arrow1.png';

import SlideProject1 from '../assets/img/founder.87fca9a158c20410b195 1.png';
import SlideProject2 from '../assets/img/founder.87fca9a158c20410b195.png';
import SlideProject3 from '../assets/img/property/home-slide-3.png';



export default function HomeProjects() {
    const [animate, setAnimate] = useState(false);
 useEffect(() => {
    const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
    const scrollHandler = () => {
        testimonialElements.forEach(testimonial => {
            const bounding = testimonial.getBoundingClientRect();
            if (bounding.top < window.innerHeight) {
                testimonial.classList.add('show');
            }
        });
    };
    window.addEventListener('scroll', scrollHandler);
    // Cleanup
    return () => {
        window.removeEventListener('scroll', scrollHandler);
    };
}, []);
    useEffect(() => {
        const handleScroll = () => {
            const element = document.querySelector('.home-rojectss');
            const bounding = element.getBoundingClientRect();
            if (
                bounding.top >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                setAnimate(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        prevArrow: <img src={CustomPrevButton} alt="Prev" className="custom-prev-button" />,
        nextArrow: <img src={CustomNextButton} alt="Next" className="custom-next-button" />,
    };

  return (
    <div>
      <div className={`home-rojectss ${animate ? 'slide-in' : ''}`}>
            <div className='container bhimaaboutus123'>
            <h2 style={{ color: '#000000', textAlign: 'center', paddingTop:'20px' }}>About Us</h2>
<div className='desktop-banner'>

<div className='row my-5'>
<div className='col-md-3'>     <img src={SlideProject1} className='img-fluid w-100 news-img' style={{borderRadius:"20px"}} /></div>
<div className='col-md-6 m-auto' style={{padding:"0rem 3rem"}}><p>Quality, trust, and authenticity are the values that define us as a company. Founded in the year 1991 by our
respected Chairman, Mr. Shiva Kumar, a finest craftsman of Mysore Pak and a connoisseur of Indian sweets,
we started serving the best Mysore Pak in the city from our first outlet in Vidyaranyapuram, Mysuru.</p><br/>
<p>Today, after having 43 outlets across South Karnataka, our goal is to be a global leader and take Mysore Pak
across the globe with the help of seamless technology. Started as a sweet manufacturer, today we have ventured
into business territories other than sweets, like hotels and real estate, following the same values that built Shree
Mahalakshmi Sweets.</p></div>
<div className='col-md-3'><img src={SlideProject2} className='img-fluid w-100 news-img' style={{borderRadius:"20px"}}  /></div>

</div>







            </div>
            <div className='mobile-banner'>
            <div className='row my-5'>
            <div className='col-6'>   <img src={SlideProject1} className='img-fluid w-100 news-img' style={{borderRadius:"20px"}} /></div>
            <div className='col-6'> <img src={SlideProject2} className='img-fluid w-100 news-img' style={{borderRadius:"20px"}}  /></div>


            </div>
            
            <p>Quality, trust, and authenticity are the values that define us as a company. Founded in the year 1991 by our
respected Chairman, Mr. Shiva Kumar, a finest craftsman of Mysore Pak and a connoisseur of Indian sweets,
we started serving the best Mysore Pak in the city from our first outlet in Vidyaranyapuram, Mysuru.</p>
<p>Today, after having 43 outlets across South Karnataka, our goal is to be a global leader and take Mysore Pak
across the globe with the help of seamless technology. Started as a sweet manufacturer, today we have ventured
into business territories other than sweets, like hotels and real estate, following the same values that built Shree
Mahalakshmi Sweets.</p>
            </div>

            </div>
        </div>
    </div>
  )
}
