import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Contact from '../components/HomeContact1';

const Contactus = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="App">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Bhima Properties | Leading Real Estate Developers in Mysore</title>
                <meta name="description" content="Get in touch with Bhima Properties for information on our latest projects and exclusive deals in Mysore, Karnataka. Your dream home awaits" />
                <link rel="canonical" href="https://bhimaproperties.com/contact-us" />
            </Helmet>

            <Contact />
        </div>
    );
}

export default Contactus;
