import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomPrevButton from '../assets/img/icon/left-arrow1.png';
import CustomNextButton from '../assets/img/icon/right-arrow1.png';

import SlideProject1 from '../assets/img/Ashvini/062.jpg';
import SlideProject2 from '../assets/img/Ashvini/063.jpg';
import SlideProject3 from '../assets/img/Ashvini/064.jpg';
import SlideProject4 from '../assets/img/Ashvini/065.jpg';
import SlideProject5 from '../assets/img/Ashvini/066.jpg';
import SlideProject6 from '../assets/img/Ashvini/067.jpg';
import SlideProject7 from '../assets/img/Ashvini/068.jpg';
import SlideProject8 from '../assets/img/Ashvini/069.jpg';
import SlideProject9 from '../assets/img/Ashvini/070.jpg';
import SlideProject10 from '../assets/img/Ashvini/071.jpg';
import SlideProject11 from '../assets/img/Ashvini/072.jpg';
import SlideProject12 from '../assets/img/Ashvini/073.jpg';
import SlideProject13 from '../assets/img/Ashvini/074.jpg';
import SlideProject14 from '../assets/img/Ashvini/075.jpg';
import SlideProject15 from '../assets/img/Ashvini/076.jpg';
import SlideProject16 from '../assets/img/Ashvini/077.jpg';
import SlideProject17 from '../assets/img/Ashvini/078.jpg';

import SlideProject18 from '../assets/img/Ashvini/085.jpg';
import SlideProject19 from '../assets/img/Ashvini/086.jpg';
import SlideProject20 from '../assets/img/Ashvini/087.jpg';
import SlideProject21 from '../assets/img/Ashvini/088.jpg';
import SlideProject22 from '../assets/img/Ashvini/089.jpg';
import SlideProject23 from '../assets/img/Ashvini/090.jpg';
import SlideProject24 from '../assets/img/Ashvini/091.jpg';
import SlideProject25 from '../assets/img/Ashvini/092.jpg';
import SlideProject26 from '../assets/img/Ashvini/093.jpg';
import SlideProject27 from '../assets/img/Ashvini/094.jpg';
import SlideProject28 from '../assets/img/Ashvini/095.jpg';
import SlideProject29 from '../assets/img/Ashvini/096.jpg';
import SlideProject30 from '../assets/img/Ashvini/097.jpg';
import SlideProject31 from '../assets/img/Ashvini/098.jpg';
import SlideProject32 from '../assets/img/Ashvini/099.jpg';
import SlideProject33 from '../assets/img/Ashvini/100.jpg';
import SlideProject34 from '../assets/img/Ashvini/101.jpg';
import SlideProject35 from '../assets/img/Ashvini/102.jpg';
import SlideProject36 from '../assets/img/Ashvini/103.jpg';
import SlideProject37 from '../assets/img/Ashvini/104.jpg';
import SlideProject38 from '../assets/img/Ashvini/105.jpg';
import SlideProject39 from '../assets/img/Ashvini/106.jpg';
import SlideProject40 from '../assets/img/Ashvini/107.jpg';



import projevt from '../assets/img/project.png';
import PointIcon1 from '../assets/img/icon/point-1.png';
import PointIcon2 from '../assets/img/icon/point-2.png';
import PointIcon3 from '../assets/img/icon/point-3.png';

export default function HomeProjects() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
  autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        prevArrow: <img src={CustomPrevButton} style={{color:"black"}} alt="Prev" className="custom-prev-button" />,
        nextArrow: <img src={CustomNextButton} alt="Next" className="custom-next-button" />,
    };
    useEffect(() => {
        const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
        const scrollHandler = () => {
            testimonialElements.forEach(testimonial => {
                const bounding = testimonial.getBoundingClientRect();
                if (bounding.top < window.innerHeight) {
                    testimonial.classList.add('show');
                }
            });
        };
        window.addEventListener('scroll', scrollHandler);
        // Cleanup
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
  return (
    <div className='resize-gallery'>
        <div className='home-rojects' style={{marginTop:'-30px'}}>
    
<div>  <br/>

            <div className='d-flex justify-content-center py-4 bhimaaboutus123'>
            <div className='projects-slide col-lg-10 col-md-11 col-12 '>

                <Slider className='home-projects-slide' {...settings}>
                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject1} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject2} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject3} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject4} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>                    
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject5} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject6} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject7} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject8} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>                    
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject9} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject10} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject11} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject12} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject13} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject14} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject15} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject16} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject17} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject18} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>                    
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject19} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject20} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject21} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject22} className='img-fluid property-img w-100' style={{borderRadius:"20px"}}></img>                    
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}}>
                        <img src={SlideProject23} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject24} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject25} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject26} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject27} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject28} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject29} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject30} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject31} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject32} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject33} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject34} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject35} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject36} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject37} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject38} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject39} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>
                    <div className='property-box' style={{borderRadius:"20px"}} >
                        <img src={SlideProject40} className='img-fluid property-img w-100'  style={{borderRadius:"20px"}}></img>
                    </div>

                </Slider>

                </div>
            </div>

        
            </div>
            </div>
        </div>
   
  )
}
