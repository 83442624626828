import React, { useEffect, useState } from 'react';

import contactImage from '../assets/img/banner/contact.png';
import whatsappImage from '../assets/img/icon/whatsapp1.png';

export default function HomeContact() {
  useEffect(() => {
    const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
    const scrollHandler = () => {
        testimonialElements.forEach(testimonial => {
            const bounding = testimonial.getBoundingClientRect();
            if (bounding.top < window.innerHeight) {
                testimonial.classList.add('show');
            }
        });
    };
    window.addEventListener('scroll', scrollHandler);
    // Cleanup
    return () => {
        window.removeEventListener('scroll', scrollHandler);
    };
}, []);
  return (
    <>
    <div className='container mt-5 bhimaaboutus123'>
      <div className='home-contact'>
      <h2 style={{ color: '#fff', textAlign: 'center', padding:'20px 0px' }}>Enquire Now</h2>

        <div className='row'>
        <div className='col-lg-6 col-12' style={{ paddingTop: "3rem" }}>
  <div className='p-3' style={{ marginLeft: "2rem" }}>
    <h1 className='text-white text-left'>Let's Chat</h1>

    <br />
    <a 
      href="https://wa.me/8214258821" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img 
        src={whatsappImage} 
        alt="WhatsApp Icon" 
        style={{ width: '89px', height: '89px' }} 
      />
    </a>
    <br />
    <br />
    <p className='text-black text-left'>
      Stay up-to-date with Bhima Properties. Contact us on WhatsApp now and get updated information on our latest projects and exclusive deals.
    </p>
  </div>
</div>





          <div className='col-lg-6 col-12'>
          <form className="row g-3" method='POST' action='./sendFormData.php' >
            <h5 className='text-center'>Book A Site Visit</h5>
            <hr style={{border:"2px solid #FFB602"}}/>
  <div class="col-md-6">
   
    <input type="text" class="form-control"  name="name" placeholder="Name" style={{border:"1px solid #CCCCCC", color:'black', borderRadius:"8px"}}/>
  </div>
  <div class="col-md-6">
  
    <input type="text" class="form-control" name="phone"  placeholder="Phone" style={{border:"1px solid #CCCCCC", color:'black', borderRadius:"8px"}}/>
  </div>
  <div class="col-12">
  
    <input type="text" class="form-control" name="email" placeholder="Email" style={{border:"1px solid #CCCCCC", color:'black', borderRadius:"8px"}}/>
  </div>
  <div class="col-12">
  <select class="form-control"  name="project"  style={{border:"1px solid #CCCCCC", color:'black', borderRadius:"8px"}}>
    <option value="" disabled selected>Select Project</option>
    <option value="ASHLESHA" style={{color:"black"}}>ASHLESHA</option>
    <option value="MRIGASHIRA" style={{color:"black"}}>MRIGASHIRA</option>
    <option value="ASHVINIPHASE2" style={{color:"black"}}>ASHVINI PHASE 2</option>
    <option value="KRITHIKALAKEVIEW" style={{color:"black"}}>KRITHIKA LAKE VIEW</option>
    <option value="PUNARVASU" style={{color:"black"}}>PUNARVASU</option>
    <option value="PUNARVASU2" style={{color:"black"}}>PUNARVASU 2</option>
    <option value="KRITHIKAPHASE2" style={{color:"black"}}>KRITHIKA PHASE 2</option>
    <option value="ASHVINIPHASE3" style={{color:"black"}}>ASHVINI PHASE 3</option>
    <option value="KRITHIKAHILLVIEW" style={{color:"black"}}>KRITHIKA HILL VIEW</option>
    <option value="BHIMAVISHAKA" style={{color:"black"}}>BHIMA VISHAKA</option>
    <option value="BHIMASINDHOOR" style={{color:"black"}}>BHIMA SINDHOOR</option>
    <option value="BHIMAPOORVAPALGUNI" style={{color:"black"}}>BHIMA POORVAPALGUNI</option>
    <option value="ASHVINIPHASE1" style={{color:"black"}}>ASHVINI PHASE 1</option>
    <option value="BHIMAROHINISIGNATURE" style={{color:"black"}}>BHIMA ROHINI SIGNATURE</option>
    <option value="BHIMABHARANI1" style={{color:"black"}}>BHIMA BHARANI 1</option>
    <option value="BHIMABHARANI2" style={{color:"black"}}>BHIMA BHARANI 2</option>
    <option value="BHIMAPOORVAPRATHAM" style={{color:"black"}}>BHIMA POORVAPRATHAM</option>
    <option value="ALLFINE" style={{color:"black"}}>ALLFINE</option>

  
  </select>
  </div>
  <div class="col-md-12">
  <input type="text" class="form-control"  name="city" placeholder="City" style={{border:"1px solid #CCCCCC", color:'black', borderRadius:"8px"}}/>
  </div>

  
  <textarea className='container col-md-12'  name="message" rows="3" placeholder=" Message" style={{border:"1px solid #CCCCCC", color:'black', borderRadius:"8px"}}></textarea>

  <div class="col-12">
    <button type="submit" class="btn btn-primary w-100" style={{background:"radial-gradient(84.91% 87.64% at 62.75% 25.51%, #F69F00 35.04%, #E6280F 94.85%)", border:"none"}} >Submit</button>
  </div>
</form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
