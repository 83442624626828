import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import FooterSubscribe from '../components/FooterSubscribe';
import HomeFaq from '../components/HomeFaq';
import HomeNews from '../components/HomeNews';
import HomeContact from '../components/HomeContact';
import About from '../components/About';

const Aboutus = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="App">
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Bhima Properties | Trusted Real Estate Developers in Mysore</title>
                <meta name="description" content="Learn about Bhima Properties, Mysore's top real estate developer offering premium residential plots for your dream home in the heritage city" />
                <link rel="canonical" href="https://bhimaproperties.com/about-us" />
            </Helmet>

            <About />
            <HomeContact />
            <HomeFaq />
            {/* <HomeNews /> */}
            {/* <FooterSubscribe /> */}
        </div>
    );
}

export default Aboutus;
