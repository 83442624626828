import React, { useEffect, useState } from 'react';
import LocationIcon from '../assets/img/icon/footer-location.svg';
import PhoneIcon from '../assets/img/icon/footer-phone.svg';
import EmailIcon from '../assets/img/icon/footer-email.svg';
import FooterBack from '../assets/img/footerbackgr.png';
import { Link } from 'react-router-dom';
import { RiWhatsappLine, RiPhoneLine } from 'react-icons/ri';
import { IoIosMail } from "react-icons/io";

export default function
  () {
    const handleWhatsAppClick = () => {
      window.open("https://api.whatsapp.com/send?phone=9741988821", "_blank");
      // Replace "yourphonenumber" with the phone number you want to send the message to
    };
  
    const handleCallClick = () => {
      window.location.href = "tel:+919741988821"; // Replace "+1234567890" with the phone number you want to call
    };
    const handleEnquireNowClick = () => {
     
    };
    useEffect(() => {
      const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
      const scrollHandler = () => {
          testimonialElements.forEach(testimonial => {
              const bounding = testimonial.getBoundingClientRect();
              if (bounding.top < window.innerHeight) {
                  testimonial.classList.add('show');
              }
          });
      };
      window.addEventListener('scroll', scrollHandler);
      // Cleanup
      return () => {
          window.removeEventListener('scroll', scrollHandler);
      };
  }, []);
  
  
  return (
    <div>
      <div className="main-footer " style={{ background: `url(${FooterBack})`, backgroundSize:"cover", backgroundRepeat:"no-repeat" }}>
        <div className="first-footer-row">
          <div className="container bhimaaboutus123">
            <div className="row">
              <div className="col col-lg-4 col-md-12 col-12">
                {/* Content for Column 1 */}
                <div className="footer-coloum">
                  <h4>About Us</h4>
                  <div className='footer-name-line'></div>
                  <p style={{fontWeight:'400', lineHeight:'22px',  textAlign:'justify', marginRight:'40px' }}>Bhima Properties is a reputed name behind some of the prestigious and advanced facilities in Mysuru. Since its inception from 2014, the motto of Bhima Properties has been to be the brand which people would love to be associated with Bhima Properties has its head office in Mysuru.</p>
                </div>
              </div>
              <div className="col col-lg-2 col-md-4 col-12">
                {/* Content for Column 1 */}
                <div className="footer-coloum">
                  <h4>Quick Links</h4>
                  <div className='footer-name-line'></div>
                  <Link to="/" style={{textDecoration:"none"}}><p className="mb-2">Home</p></Link>
                  <Link to="/projects" style={{textDecoration:"none"}}> <p className="mb-2">Projects</p></Link>
                  <Link to="/about-us" style={{textDecoration:"none"}}> <p className="mb-2">About Us</p></Link>
                  <Link to="/privacy-policy" style={{textDecoration:"none"}}> <p className="mb-2">Privacy Policy</p></Link>

                  <Link to="/contact-us" style={{textDecoration:"none"}}> <p className="mb-2">Contact Us</p></Link>
                </div>
              </div>
              <div className="col col-lg-3 col-md-8  col-12">
                {/* Content for Column 1 */}
                <div className="footer-coloum">
                  <h4>Our Projects</h4>
                  <div className='footer-name-line'></div>
                  <Link to="/ashlesha-project" style={{textDecoration:"none"}}><p className="mb-2">ASHLESHA</p></Link>
                  <Link to="/mrigashira-project" style={{textDecoration:"none"}}><p className="mb-2">MRIGASHIRA</p></Link>   
                  <Link to="/ashvini-phase-2-project" style={{textDecoration:"none"}}><p className="mb-2">ASHVINI PHASE 2</p></Link>
                  <Link to="/krithika-lake-view-project" style={{textDecoration:"none"}}><p className="mb-2">KRITHIKA LAKE VIEW</p></Link>
                  <Link to="/punarvasu-project" style={{textDecoration:"none"}}><p className="mb-2">PUNARVASU</p></Link>
                  <Link to="/projects" style={{textDecoration:"none"}}><p className="mb-2 test"  style={{color:"rgb(240, 126, 1)"}}>+ See More</p></Link>
                </div>
              </div>
              <div className="col col-lg-3 col-md-12 col-12">
  <div className="footer-coloum">
    <h4>Contact Us</h4>
    <div className='footer-name-line'></div>

    <div className="contact-info">
      <div className="row g-2 ">
        <div className="col-1">
         <img src={LocationIcon} className='img-fluid' width='20px' height='20px'></img>
        </div>
        <div className="col">
          <p>1002, 1st Floor, 2nd Main, 4th Cross, Vidyaranyapuram, Mysuru – 570008.</p>
        </div>
      </div>
      <div className="row g-2 ">
        <div className="col-1">
        <img src={EmailIcon} className='img-fluid' width='20px' height='20px'></img>
        </div>
        <div className="col ">
          <p>+91 97419 88821</p>
        </div>
      </div>
      <div className="row  g-2 ">
        <div className="col-1">
        <img src={EmailIcon} className='img-fluid' width='20px' height='20px'></img>
        </div>
        <div className="col ">
          <p>+91 82142 58821</p>
        </div>
      </div>



      <div className="row g-2 ">
        <div className="col-1">
        <img src={PhoneIcon} className='img-fluid' width='20px' height='20px'></img>
        </div>
        <div className="col ">
          <p>info@bhimaproperties.com</p>
        </div>
      </div>
    </div>
  </div>
</div>


            </div>



          </div>
{/* <div className="whatsapp-call-icons">
  <div className="container">
    <div className="row justify-content-end">
      <div className="col-auto">
        <button className="icon-button" style={{borderRadius:"25px", padding:"10px", border:"1px solid white"}}  onClick={handleWhatsAppClick}>
          <RiWhatsappLine size={40} color="#25D366" />
        </button>
      </div>
    </div>
    <div className="row justify-content-end">
      <div className="col-auto">
        <button className="icon-button" style={{borderRadius:"25px", padding:"10px", marginTop: "10px", border:"1px solid white"}}  onClick={handleCallClick}>
          <RiPhoneLine size={40} color="#007bff" />
        </button>
      </div>
    </div>
  </div>
</div> */}







<div style={{ position: 'fixed', right: '0px', top: '50%', transform: 'translateY(-50%)' }}>


<div className="enquire-now-button desktop-banner" style={{ position: 'fixed', right: '-40px', position:'relative', marginBottom:'40px',  transform: "rotate(270deg)" }}>
<Link to="/contact-us" style={{ textDecoration: 'none', color: 'inherit' }}>
      <button style={{ padding: "10px", border: "1px solid white", backgroundColor: "rgb(240, 126, 1)", color: "#fff", fontSize: "16px", borderRadius:"0px 10px 0px 0px"}}> Enquire Now</button>
    </Link>

</div>
<div className="enquire-now-button desktop-banner" style={{ position: 'fixed', right: '-80px', position:'relative', marginBottom:'0px'}}>

      <button style={{ padding: "10px", border: "1px solid white", backgroundColor: "rgb(240, 126, 1)", color: "#fff", fontSize: "16px"}}  onClick={handleWhatsAppClick}>  <RiWhatsappLine size={24} color="white" /></button>
  

</div>
<div className="enquire-now-button desktop-banner" style={{ position: 'fixed', right: '-80px', position:'relative', marginBottom:'0px' }}>

      <button style={{ padding: "10px", border: "1px solid white", backgroundColor: "rgb(240, 126, 1)", color: "#fff", fontSize: "16px", borderRadius:"0px 0px 0px 10px"}} onClick={handleCallClick}>  <RiPhoneLine size={24} color="white" /></button>
  

</div>

</div>



<div>
      <div className="mobile-banner" style={{ position: 'fixed', bottom: '0', left: '0', width: '100%', backgroundColor: '#fff', marginBottom:'-10px', boxShadow: '0px -1px 5px rgba(0, 0, 0, 0.1)', zIndex: '999' }}>
        <button style={{ padding: '10px', border: '1px solid white', backgroundColor: 'rgb(240, 126, 1)', color: '#fff', fontSize: '16px', borderRadius: '5px', cursor: 'pointer', marginBottom: '10px', width: '50%' }} onClick={handleCallClick}>Call</button>
        <button style={{ padding: '10px', border: '1px solid white', backgroundColor: 'rgb(240, 126, 1)', color: '#fff', fontSize: '16px', borderRadius: '5px', cursor: 'pointer', width: '50%' }} onClick={handleWhatsAppClick}>WhatsApp</button>
      </div>
    </div>




          <div className="col-12 text-center " style={{ fontSize:'20px', fontWeight:'500', padding:'15px 0px', color:'#fff'}}>
            <div className='container'>
              <p className='mb-0'>Bhima Properties © 2024 . Developed By Techrowth pvt ltd</p>
              </div>
            </div>
        </div>
      </div>

      
    </div>
  )
}
