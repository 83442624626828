import React, { useEffect } from 'react';

function Privacypolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <br/><br/>
      <h1>Privacy Policy for Bhima Properties</h1>

      <p>
        This Privacy Policy governs the manner in which Bhima Properties and its subsidiaries, partners, agents, and affiliates collect, use, maintain, and disclose information collected from users of our website. This policy applies to the website and all products and services offered by Bhima Properties.
      </p>

      <h2>1. Personal Identification Information</h2>
      <p>
        We may collect personal identification information from Users in various ways, including, but not limited to:
      </p>
      <ul>
        <li>When Users visit our site, subscribe to our newsletter, fill out a form, or in connection with other activities, services, features, or resources we make available on our site.</li>
        <li>Users may be asked for their name, email address, mailing address, phone number, city, project of interest, and message.</li>
      </ul>
      <p>
        Users may visit our site anonymously. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain site-related activities.
      </p>

      <h2>2. Non-Personal Identification Information</h2>
      <p>
        We may collect non-personal identification information about Users whenever they interact with our site. Non-personal identification information may include:
      </p>
      <ul>
        <li>The browser name, the type of computer, and technical information about Users’ means of connection to our site, such as the operating system and the Internet service providers utilized.</li>
      </ul>

      <h2>3. Web Browser Cookies and Tracking Technologies</h2>
      <p>
        Our site may use “cookies” to enhance User experience. Cookies help track information about Users to:
      </p>
      <ul>
        <li>Analyze site traffic and optimize marketing campaigns.</li>
        <li>Understand how Users interact with our site.</li>
      </ul>
      <p>
        Users may choose to set their web browser to refuse cookies or alert them when cookies are being sent. However, some parts of the site may not function properly if cookies are disabled. Our tracking technologies assist in collecting browsing data and improving user experience across the site.
      </p>

      <h2>4. How We Use Collected Information</h2>
      <p>Bhima Properties may collect and use Users’ personal information for the following purposes:</p>
      <ul>
        <li><strong>To improve customer service:</strong> Information helps us respond to customer service requests and support needs more efficiently.</li>
        <li><strong>To personalize User experience:</strong> We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our site.</li>
        <li><strong>To improve our site:</strong> We use feedback to enhance our products and services.</li>
        <li><strong>To communicate marketing and promotional offers:</strong> Information may be used to send Users emails about topics they have agreed to receive, such as project updates, promotions, or newsletters.</li>
      </ul>

      <h2>5. Sharing Personal Information of Users</h2>
      <p>
        We do not sell, trade, or rent Users’ personal identification information to others. However, we may share generic aggregated demographic information not linked to any personal identification information regarding Users with our subsidiaries, business partners, trusted affiliates, and advertisers for the purposes outlined above.
      </p>

      <h2>6. User Rights</h2>
      <ul>
        <li><strong>Access, update, or delete their personal information:</strong> Users can contact us directly to make requests.</li>
        <li><strong>Opt-out of data tracking and marketing communications:</strong> Users can adjust cookie preferences or unsubscribe from our emails.</li>
      </ul>

      <h2>7. Data Retention</h2>
      <p>
        We will retain personal data only for as long as necessary to fulfill the purposes for which it was collected. Once data is no longer required, we will securely delete or anonymize it.
      </p>

      <h2>8. Data Security</h2>
      <p>
        We adopt appropriate data collection, storage, and processing practices, as well as security measures, to protect against unauthorized access, alteration, disclosure, or destruction of Users' personal information.
      </p>

      <h2>9. Compliance with Legal Frameworks</h2>
      <p>
        We strive to comply with industry standards and legal requirements to ensure data protection and privacy compliance.
      </p>

      <h2>10. Third-Party Links</h2>
      <p>
        Our site may include links to third-party websites or services. These websites have their own privacy policies, and we are not responsible for their practices.
      </p>

      <h2>11. Children’s Privacy</h2>
      <p>
        We do not knowingly collect personal identification information from individuals under the age of 18. If we discover that we have inadvertently collected such information, we will take immediate steps to delete it.
      </p>

      <h2>12. Changes to This Privacy Policy</h2>
      <p>
        Bhima Properties reserves the right to update this privacy policy at any time. We encourage Users to frequently check this page for any changes.
      </p>

      <h2>Your Acceptance of These Terms</h2>
      <p>
        By using this site, Users signify their acceptance of this policy. If you do not agree to this policy, please do not use our site.
      </p>

      <h2>Contacting Us</h2>
      <p>If you have any questions, you can reach us at:</p>
      <p>Bhima Properties</p>
      <p>Email: info@bhimaproperties.com</p>
      <p>Phone: +91 82142 58821</p><br/><br/>
    </div>
  );
}

export default Privacypolicy;
