import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import News1 from '../assets/img/Contact US.png';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import whatsappImage from '../assets/img/icon/whatsapp1.png';

export default function HomeContact() {

 // Function to send data to the CRM
//  const [formData, setFormData] = useState({
//   name: '',
//   phone: '',
//   email: '',
//   project: '',
//   message: ''
// });

// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setFormData({ ...formData, [name]: value });
// };

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   try {
//     const response = await axios.post('./sendFormData.php', formData);
//     if (response.status === 200) {
//       console.log('Data sent successfully.');

//       // Handle success
//     } else {
//       console.error('Failed to send data.');
//       // Handle failure
//     }
//   } catch (error) {
//     console.error('Error:', error);
//     // Handle error
//   }
// };

useEffect(() => {
  const testimonialElements = document.querySelectorAll('.bhimaaboutus123');
  const scrollHandler = () => {
    testimonialElements.forEach(testimonial => {
      const bounding = testimonial.getBoundingClientRect();
      if (bounding.top < window.innerHeight) {
        testimonial.classList.add('show');
      }
    });
  };
  window.addEventListener('scroll', scrollHandler);
  // Cleanup
  return () => {
    window.removeEventListener('scroll', scrollHandler);
  };
}, []);

  return (
    <>
                                    <img src={News1} className='img-fluid w-100 news-img ' />

                {/* <h2 style={{ color: '#000000', textAlign: 'center', padding:'20px 0px' }}>Enquire Now</h2> */}
    <div className='container my-5 '>
      <div className='home-contact1' style={{borderRadius:"10px", color:"white", border:"1px solid #839CA3", background:"#839CA3"}}>

        <div className='row'>
        <div className='col-lg-6 col-12' style={{ paddingTop: "3rem" }}>
  <div className='p-3' style={{ marginLeft: "2rem" }}> {/* Added margin-left */}
    <h1 className='text-black text-left'>Let's Chat</h1>
    <br />
    <a 
      href="https://wa.me/8214258821" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img src={whatsappImage} alt="WhatsApp Icon" style={{ width: '89px', height: '89px' }} />
    </a>
    <br />
    <br />
    <p className='text-black text-left text-white'>
      Stay up-to-date with Bhima Properties, Contact us on WhatsApp now and get updated information on our latest projects and exclusive deals.
    </p>
  </div>

  <div className='d-flex' style={{ marginLeft: "1rem" }}> {/* Added margin-left */}
    <div className='col-2' style={{ textAlign: "center", margin: "auto" }}>
      <FontAwesomeIcon icon={faMapMarkerAlt} style={{ width: "2rem", height: "2rem", textAlign: "center" }} />
    </div>
    <div className='col-10'>
      1002, 4th Cross, 2nd Main Rd, opp. Shree Mahalakshmi Sweets, Vidyaranyapuram, Mysuru, Karnataka 570008
    </div>
  </div>
  <br />
  <div className='d-flex' style={{ marginLeft: "1rem" }}> {/* Added margin-left */}
    <div className='col-2' style={{ textAlign: "center", margin: "auto" }}>
      <FontAwesomeIcon icon={faPhone} style={{ width: "2rem", height: "2rem", textAlign: "center" }} />
    </div>
    <div className='col-10'>
      +91 82142 58821
    </div>
  </div>
  <br />
  <div className='d-flex' style={{ marginLeft: "1rem" }}> {/* Added margin-left */}
    <div className='col-2' style={{ textAlign: "center", margin: "auto" }}>
      <FontAwesomeIcon icon={faEnvelope} style={{ width: "2rem", height: "2rem", textAlign: "center" }} />
    </div>
    <div className='col-10'>
      info@bhimaproperties.com
    </div>
  </div>
  <br />
</div>



          <div className='col-lg-6 col-12'>
          <form className="row g-3" method='POST' action='./sendFormData.php' >
                <h5 className='text-center' style={{ color: "black" }}>Book A Site Visit</h5>
                <hr style={{ border: "2px solid #FFB602" }} />
                <div className="col-md-6">
                  <input type="text" className="form-control" name="name" placeholder="Name" style={{ border: "1px solid #CCCCCC", color: 'black', borderRadius: "8px" }} />
                </div>
                <div className="col-md-6">
                  <input type="text" className="form-control" name="phone"   placeholder="Phone" style={{ border: "1px solid #CCCCCC", color: 'black', borderRadius: "8px" }} />
                </div>
                <div className="col-12">
                  <input type="text" className="form-control" name="email"   placeholder="Email" style={{ border: "1px solid #CCCCCC", color: 'black', borderRadius: "8px" }} />
                </div>
                <div className="col-12">
                  <select className="form-control" name="project"   style={{ border: "1px solid #CCCCCC", color: 'black', borderRadius: "8px" }}>
                   
                    <option value="" disabled selected>Select Project</option>
    <option value="ASHLESHA" style={{color:"black"}}>ASHLESHA</option>
    <option value="MRIGASHIRA" style={{color:"black"}}>MRIGASHIRA</option>
    <option value="ASHVINIPHASE2" style={{color:"black"}}>ASHVINI PHASE 2</option>
    <option value="KRITHIKALAKEVIEW" style={{color:"black"}}>KRITHIKA LAKE VIEW</option>
    <option value="PUNARVASU" style={{color:"black"}}>PUNARVASU</option>
    <option value="PUNARVASU2" style={{color:"black"}}>PUNARVASU 2</option>
    <option value="KRITHIKAPHASE2" style={{color:"black"}}>KRITHIKA PHASE 2</option>
    <option value="ASHVINIPHASE3" style={{color:"black"}}>ASHVINI PHASE 3</option>
    <option value="KRITHIKAHILLVIEW" style={{color:"black"}}>KRITHIKA HILL VIEW</option>
    <option value="BHIMAVISHAKA" style={{color:"black"}}>BHIMA VISHAKA</option>
    <option value="BHIMASINDHOOR" style={{color:"black"}}>BHIMA SINDHOOR</option>
    <option value="BHIMAPOORVAPALGUNI" style={{color:"black"}}>BHIMA POORVAPALGUNI</option>
    <option value="ASHVINIPHASE1" style={{color:"black"}}>ASHVINI PHASE 1</option>
    <option value="BHIMAROHINISIGNATURE" style={{color:"black"}}>BHIMA ROHINI SIGNATURE</option>
    <option value="BHIMABHARANI1" style={{color:"black"}}>BHIMA BHARANI 1</option>
    <option value="BHIMABHARANI2" style={{color:"black"}}>BHIMA BHARANI 2</option>
    <option value="BHIMAPOORVAPRATHAM" style={{color:"black"}}>BHIMA POORVAPRATHAM</option>
    <option value="ALLFINE" style={{color:"black"}}>ALLFINE</option>
                  </select>
                </div>
                <div className="col-md-12">
                  <input type="text" className="form-control" name="city"  placeholder="City" style={{ border: "1px solid #CCCCCC", color: 'black', borderRadius: "8px" }} />
                </div>
                <textarea className='container col-md-12' name="message"   rows="3" placeholder=" Message" style={{ border: "1px solid #CCCCCC", color: 'black', borderRadius: "8px" }}></textarea>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary w-100" style={{ background: "radial-gradient(84.91% 87.64% at 62.75% 25.51%, #F69F00 35.04%, #E6280F 94.85%)", border: "none" }}>Submit</button>
                </div>
              </form>

          </div>
        </div>
        </div>
    </div>
        <iframe className='bhimaaboutus123' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3898.4429318816274!2d76.6463138106436!3d12.285930287919838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf6f93a76cde65%3A0xf9ae747fc0130fd7!2sBhima%20Properties!5e0!3m2!1sen!2sin!4v1706158042053!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
   
    </>
  )
}
